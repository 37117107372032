import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { GetReviewsRequest } from "../slices/reviewsSlice";

async function queryReviews(request: GetReviewsRequest): Promise<AxiosPromise> {
  const body = {
    challengeId: request.challengeId,
    reviewerTeamId: request.reviewerTeamId,
    states: request.states,
    maxReviews: request.maxReviews,
  };

  return await axios.post(`challenges/v1/reviews-query`, body);
}

type OverrideReviewRequest = {
  challengeId: string;
  reviewerComment?: string;
  score: number;
};

async function overrideReview(
  request: OverrideReviewRequest
): Promise<AxiosPromise> {
  const body = {
    challengeId: request.challengeId,
    reviewerComment: request.reviewerComment,
    score: request.score,
  };

  return await axios.post(`challenges/v1/override-review`, body);
}

export { queryReviews, overrideReview };
