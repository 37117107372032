import { AxiosPromise } from "axios";
import {
  AlertResolution,
  CheckInStatsOverview,
  UserActivityStatsResponse,
  UserAlert,
} from "../models/checkInStats";
import axios from "../../../utils/axios";

export type CheckInStatsRequest = {
  orgId: string;
  timeRange: string;
};

type GetUserAlertsResponse = {
  data: UserAlert[];
  meta: {};
};

type GetAlertResolutionsResponse = {
  data: AlertResolution[];
  meta: {};
};

type GetCheckInStatsOverviewResponse = {
  data: CheckInStatsOverview;
  meta: {};
};

type GetUserActivityResponse = {
  data: UserActivityStatsResponse;
  meta: {};
};

async function getUserAlertsAsync(
  body: CheckInStatsRequest
): Promise<AxiosPromise<GetUserAlertsResponse>> {
  return await axios.post("/stats/checkin/v1/user-alerts", body);
}

async function getAlertResolutionsAsync(
  body: CheckInStatsRequest
): Promise<AxiosPromise<GetAlertResolutionsResponse>> {
  return await axios.post("/stats/checkin/v1/alert-resolutions", body);
}

async function getOverviewAsync(
  body: CheckInStatsRequest
): Promise<AxiosPromise<GetCheckInStatsOverviewResponse>> {
  return await axios.post("/stats/checkin/v1/overview", body);
}

async function getUserActivityAsync(
  body: CheckInStatsRequest
): Promise<AxiosPromise<GetUserActivityResponse>> {
  return await axios.post("/stats/checkin/v1/user-activity", body);
}

export {
  getUserAlertsAsync,
  getAlertResolutionsAsync,
  getOverviewAsync,
  getUserActivityAsync,
};
