import { Navigate, RouteObject } from "react-router-dom";
import VisiblyOSLayout from "../visiblyOS/VisiblyOSLayout";
import { lazy } from "react";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";

const DirectWorkforceAccreditations = lazy(
  () => import("../visiblyOS/features/workforce/pages/Accreditations")
);

const DirectWorkforceWorkers = lazy(
  () => import("../visiblyOS/features/workforce/pages/Workers")
);

export const workforceRoutes = [
  {
    path: "compliance",
    element: (
      <CanViewCapabilityRouteGuard capability="accreditations">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "workforce",
        children: [
          {
            path: "",
            element: <Navigate to="/compliance/workforce/workers" />,
          },
          {
            path: "accreditations",
            element: <DirectWorkforceAccreditations />,
          },
          {
            path: "workers",
            element: <DirectWorkforceWorkers />,
          },
        ],
      },
    ],
  },
] as RouteObject[];
