import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import Loader from "../components/Loader";

const Root = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

interface BasicType {
  children?: React.ReactNode;
}

const Basic: React.FC<BasicType> = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Suspense fallback={<Loader />}>
        {children}
        <Outlet />
      </Suspense>
    </Root>
  );
};

export default Basic;
