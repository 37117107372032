import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  bulkImport,
  validateBulkImportCsv,
} from "../services/activityTypesCsvService";
import {
  BulkImportActivityTypeData,
  ActivityTypeDataWithValidation,
} from "../models/activityType";
import { uploadFileToFileStorage } from "../../../services/s3ClientStorageService";

type UploadAndValidateActivityTypesCsvRequest = {
  orgId: string;
  file: File;
};

type ImportActivityTypesRequest = {
  orgId: string;
  activityTypes: BulkImportActivityTypeData[];
};

type UploadActivityTypesCsvRejectPayload = {
  error: string;
};

export const uploadAndValidateActivityTypesCsv = createAsyncThunk(
  "activityTypesCsv/uploadAndValidateActivityTypes",
  async (
    request: UploadAndValidateActivityTypesCsvRequest,
    { rejectWithValue }
  ) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }

    try {
      var result = await validateBulkImportCsv({
        orgId: request.orgId,
        importId: importId,
      });

      return result.data;
    } catch (err: any) {
      return rejectWithValue({ error: err });
    }
  }
);

export const importActivityTypes = createAsyncThunk<
  null,
  ImportActivityTypesRequest,
  { rejectValue: UploadActivityTypesCsvRejectPayload }
>(
  "activityTypesCsv/importActivityTypes",
  async (request, { rejectWithValue }) => {
    try {
      await bulkImport({
        orgId: request.orgId,
        activityTypes: request.activityTypes,
      });

      return null;
    } catch (err: any) {
      return rejectWithValue({ error: err });
    }
  }
);

type ActivityTypesCsvFileState = {
  status: LoadingStatus;
  activityTypesWithValidation: ActivityTypeDataWithValidation[];
  error: string | null;
};

const initialState: ActivityTypesCsvFileState = {
  status: LoadingStatus.idle,
  activityTypesWithValidation: [],
  error: null,
};

const activityTypesCsvFileSlice = createSlice({
  name: "activityTypesCsv",
  initialState,
  reducers: {
    clearUploadActivityTypesCsv: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      uploadAndValidateActivityTypesCsv.pending,
      (state, action) => {
        return { ...state, status: LoadingStatus.loading };
      }
    );
    builder.addCase(
      uploadAndValidateActivityTypesCsv.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          activityTypesWithValidation: action.payload.data,
        };
      }
    );
    builder.addCase(
      uploadAndValidateActivityTypesCsv.rejected,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.failed,
          activityTypesWithValidation: [],
        };
      }
    );

    builder.addCase(importActivityTypes.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importActivityTypes.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        error: null,
      };
    });
    builder.addCase(importActivityTypes.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: action.payload!.error,
      };
    });
  },
});

export const { clearUploadActivityTypesCsv } =
  activityTypesCsvFileSlice.actions;

export default activityTypesCsvFileSlice.reducer;
