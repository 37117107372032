import { useRoutes } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes/routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

// import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";
import { AuthProvider } from "./contexts/AmplifyContext";
import { store } from "./redux/store";
import * as Sentry from "@sentry/react";
import { sentryConfig } from "./config";
import { BrowserTracing } from "@sentry/tracing";
import { SnackbarProvider } from "notistack";

import ThirdPartyServicesProvider from "./components/ThirdPartyServicesProvider";
import AppEntryOrganisationMismatchPrompter from "./features/organisation/pages/chooseOrganisation/AppEntryOrganisationMismatchPrompter";

const clientSideEmotionCache = createEmotionCache();

Sentry.init({
  dsn: sentryConfig.dsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: sentryConfig.enabled === "true",
  environment: sentryConfig.environment,
  release: sentryConfig.version,
});

declare global {
  interface Window {
    posthog: any;
    intercomSettings: any;
    Intercom: any;
  }
}

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  return (
    <ReduxProvider store={store}>
      <ThirdPartyServicesProvider>
        <CacheProvider value={emotionCache}>
          <SnackbarProvider maxSnack={3}>
            <HelmetProvider>
              <Helmet titleTemplate="%s | Visibly" defaultTitle="Visibly" />
              {/* @ts-ignore */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>
                    <AppEntryOrganisationMismatchPrompter>
                      {content}
                    </AppEntryOrganisationMismatchPrompter>
                  </AuthProvider>
                </MuiThemeProvider>
              </LocalizationProvider>
            </HelmetProvider>
          </SnackbarProvider>
        </CacheProvider>
      </ThirdPartyServicesProvider>
    </ReduxProvider>
  );
}

export default App;
