import { Navigate, RouteObject } from "react-router-dom";
import ExperimentDashboard from "../../features/experiment/ExperimentDashboardLayout";
import { lazy } from "react";

const ProtoDirectWorkforceAccreditations = lazy(
  () => import("../../features/experiment/pages/directWorkforce/Accreditations")
);

const DirectWorkforceDashboard = lazy(
  () => import("../../features/experiment/pages/directWorkforce/Dashboard")
);

const ProtoDirectWorkforceWorkers = lazy(
  () => import("../../features/experiment/pages/directWorkforce/Workers")
);

const DirectWorkforceProjectRoles = lazy(
  () => import("../../features/experiment/pages/directWorkforce/ProjectRoles")
);

const DirectWorkforceWorkerDetails = lazy(
  () => import("../../features/experiment/pages/directWorkforce/worker/Details")
);

const DirectWorkforceWorkerAccreditations = lazy(
  () =>
    import(
      "../../features/experiment/pages/directWorkforce/worker/Accreditations"
    )
);

const DirectWorkforceWorkerWorkerRoles = lazy(
  () =>
    import("../../features/experiment/pages/directWorkforce/worker/WorkerRoles")
);

const DirectWorkforceWorkerRoles = lazy(
  () => import("../../features/experiment/pages/directWorkforce/WorkerRoles")
);

export const experimentDirectWorkforceRoutes = [
  {
    path: "experiment",
    element: <ExperimentDashboard />,
    children: [
      {
        path: "direct-workforce",
        element: <Navigate to="/experiment/direct-workforce/dashboard" />,
      },
      {
        path: "direct-workforce/dashboard",

        element: <DirectWorkforceDashboard />,
      },
      {
        path: "direct-workforce/project-roles",
        element: <DirectWorkforceProjectRoles />,
      },
      {
        path: "direct-workforce/workers",
        element: <ProtoDirectWorkforceWorkers />,
      },
      {
        path: "direct-workforce/accreditations",
        element: <ProtoDirectWorkforceAccreditations />,
      },
      {
        path: "direct-workforce/worker-roles",
        element: <DirectWorkforceWorkerRoles />,
      },
      {
        path: "direct-workforce/workers/:workerId",
        children: [
          {
            path: "details",
            element: <DirectWorkforceWorkerDetails />,
          },
          {
            path: "worker-roles",
            element: <DirectWorkforceWorkerWorkerRoles />,
          },
          {
            path: "accreditations",
            element: <DirectWorkforceWorkerAccreditations />,
          },
        ],
      },
    ],
  },
] as RouteObject[];
