import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as groupsService from "../services/groupsService";
import { LoadingStatus } from "../../../../models/loadingStatus";
import { Group } from "../../../models/group";
import { GroupWithRoles } from "../models/groups";

export type CreateGroupRequest = {
  name: string;
  reference?: string;
  description?: string;
  orgId: string;
  roleIds: string[];
};

export const createGroup = createAsyncThunk(
  "groups/create",
  async (request: CreateGroupRequest, { rejectWithValue }) => {
    try {
      return (await groupsService.createGroup(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

export type QueryGroupsRequest = {
  orgId: string;
};

export const queryGroups = createAsyncThunk(
  "groups/query",
  async (request: QueryGroupsRequest, { rejectWithValue }) => {
    try {
      return (await groupsService.queryGroups(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

export const getGroup = createAsyncThunk(
  "groups/get",
  async (groupId: string, { rejectWithValue }) => {
    try {
      return (await groupsService.getGroup(groupId)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type GroupState = {
  status: LoadingStatus;
  groups: Group[];
  group?: GroupWithRoles;
  error: string | null;
};

const initialState: GroupState = {
  status: LoadingStatus.idle,
  groups: [],
  error: null,
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createGroup.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(createGroup.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(queryGroups.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryGroups.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        groups: action.payload.data,
      };
    });
    builder.addCase(queryGroups.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(getGroup.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getGroup.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        group: action.payload.data,
      };
    });
    builder.addCase(getGroup.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export default groupsSlice.reducer;
