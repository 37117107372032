import React from "react";

import useAuth from "../../hooks/useAuth";
import NavigateToUserHome from "../NavigateToUserHome";

interface VisiblyAdminGuardType {
  children: React.ReactNode;
}

function VisiblyAdminGuard({ children }: VisiblyAdminGuardType) {
  const { isVisiblySupport, isVisiblyAdmin } = useAuth();

  if (!isVisiblySupport && !isVisiblyAdmin) {
    return <NavigateToUserHome />;
  }

  return <>{children}</>;
}

export default VisiblyAdminGuard;
