import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import { Button, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";

import { resetPassword } from "../../services/accountsService";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        try {
          const emailSent = (await resetPassword({ email: values.email })).data
            .data.emailSent;

          if (emailSent) {
            navigate("/auth/check-email", {
              state: { email: values.email, isResend: false },
            });
          } else {
            setStatus({ success: false });
            setSubmitting(false);
            const snackbarKey = enqueueSnackbar("Email doesn't exist", {
              variant: "error",
              onClick: () => closeSnackbar(snackbarKey),
            });
          }
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setSubmitting(false);
          const snackbarKey = enqueueSnackbar(message, {
            variant: "error",
            onClick: () => closeSnackbar(snackbarKey),
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            data-cy="emailField"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            data-cy="resetPasswordButton"
            disabled={isSubmitting}
          >
            Reset password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ForgotPasswordForm;
