import { useCallback, useEffect, useMemo, useState } from "react";
import useAppDispatch from "../../../../../../hooks/useAppDispatch";
import { getCurrentOrgId } from "../../../../../../contexts/AmplifyContext";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  bulkUnassign,
  queryAssignedRoles,
  resetState,
  unassign,
} from "../../../slices/groupRolesSlice";
import { ColDef, GridApi, ICellRendererParams } from "ag-grid-enterprise";
import { LoadingStatus } from "../../../../../../models/loadingStatus";
import Loader from "../../../../../../components/Loader";
import ErrorComponent from "../../../../../components/ErrorComponent";
import AgGridTable from "../../../../../../components/AgGridTable";
import StatusDot from "../../../../../components/StatusDot";
import {
  mapRoleComplianceStatus,
  reverseMapRoleComplianceStatus,
} from "../../../utils/groupRoleUtils";
import AccreditationDetailCellRenderer from "../../../../../components/AccreditationDetailCellRenderer";
import { AssignedRole, UnassignDto } from "../../../models/assignedRoles";
import { RowSelectedEvent } from "ag-grid-community";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import UnassignMultipleModal from "./UnassignMultipleModal";
import UnassignSingleModal from "./UnassignSingleModal";

function WorkerRoles() {
  const dispatch = useAppDispatch();
  const currentOrgId = getCurrentOrgId();
  const { groupId } = useParams();
  const [tableLength, setTableLength] = useState<number>(0);
  const [selectedAssignedRoles, setSelectedAssignedRoles] = useState<
    AssignedRole[]
  >([]);
  const [selectedAssignedRole, setSelectedAssignedRole] =
    useState<AssignedRole>();
  const [showSingleModal, setShowSingleModal] = useState<boolean>(false);
  const [showMultipleModal, setShowMultipleModal] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const assignedRoles = useAppSelector(
    (state) => state.groupRoles.assignedRoles
  );
  const assignedRolesStatus = useAppSelector(
    (state) => state.groupRoles.status
  );

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    if (currentOrgId && groupId) {
      dispatch(
        queryAssignedRoles({
          groupId: groupId,
          orgId: currentOrgId,
        })
      );
    }
  }, [dispatch, currentOrgId, groupId]);

  const detailCellRenderer = useCallback(AccreditationDetailCellRenderer, []);

  const handleUnassign = async (rolesToUnassign: UnassignDto[]) => {
    if (!groupId && !currentOrgId) {
      const snackbarKey = enqueueSnackbar(
        "Unable to unassign, please refresh your page and try again",
        {
          variant: "error",
          onClick: () => closeSnackbar(snackbarKey),
        }
      );
      return;
    }
    if (rolesToUnassign.length === 1) {
      await dispatch(
        unassign({
          groupId: groupId!,
          orgId: currentOrgId!,
          userIds: rolesToUnassign[0].userIds,
          roleId: rolesToUnassign[0].roleId,
        })
      );
    } else {
      await dispatch(
        bulkUnassign({
          groupId: groupId!,
          orgId: currentOrgId!,
          rolesToUnassign: rolesToUnassign,
        })
      );
    }

    setSelectedAssignedRoles([]);
    dispatch(
      queryAssignedRoles({
        groupId: groupId!,
        orgId: currentOrgId!,
      })
    );

    const snackbarKey = enqueueSnackbar(
      `Successfully unassigned worker${
        rolesToUnassign.length !== 1 ? "s" : ""
      }`,
      {
        variant: "success",
        onClick: () => closeSnackbar(snackbarKey),
      }
    );
  };

  let columns: ColDef[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      flex: 0.25,
    },
    {
      field: "workerName",
      headerName: "Worker",
      sortable: true,
      flex: 1,
      cellRenderer: "agGroupCellRenderer",
    },
    {
      field: "roleName",
      headerName: "Role",
      sortable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
    },
    {
      field: "complianceStatus",
      headerName: "Status",
      sortable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <>
            <StatusDot
              marginBottom={2}
              colour={
                params.value === "notAccredited"
                  ? "red"
                  : params.value === "atRisk"
                  ? "amber"
                  : "green"
              }
            />
            <p>{mapRoleComplianceStatus(params.value)}</p>
          </>
        );
      },
    },
    {
      headerName: "Accreditations",
      sortable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
      cellRenderer: (params: ICellRendererParams<AssignedRole>) => {
        var requiredAccreditationCount =
          params.data?.mandatoryAccreditations.length ?? 0;

        if (params.data?.interchangeableAccreditations) {
          requiredAccreditationCount +=
            params.data?.interchangeableAccreditations.length;
        }

        var obtainedAccreditationsCount =
          params.data?.mandatoryAccreditations.filter(
            (el) => el.status !== "expired" && el.status !== "notHeld"
          ).length ?? 0;

        if (params.data?.interchangeableAccreditations) {
          obtainedAccreditationsCount +=
            params.data.interchangeableAccreditations
              .map((el) => {
                var statuses = el.map((e) => e.status);
                if (
                  !statuses.includes("valid") &&
                  !statuses.includes("expiring")
                ) {
                  return 0;
                }
                return 1;
              })
              .reduce((partialSum: number, a: number) => partialSum + a, 0);
        }
        return (
          <p>{`${obtainedAccreditationsCount}/${requiredAccreditationCount}`}</p>
        );
      },
    },
    {
      headerName: "Due to expire",
      sortable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
      cellRenderer: (params: ICellRendererParams<AssignedRole>) => {
        var dueToExpireCount =
          params.data?.mandatoryAccreditations.filter(
            (el) => el.status === "expiring"
          ).length ?? 0;

        if (params.data?.interchangeableAccreditations) {
          dueToExpireCount += params.data.interchangeableAccreditations
            .map((el) => {
              var statuses = el.map((e) => e.status);
              if (
                !statuses.includes("valid") &&
                statuses.includes("expiring")
              ) {
                return 1;
              }
              return 0;
            })
            .reduce((partialSum: number, a: number) => partialSum + a, 0);
        }
        return <p>{dueToExpireCount}</p>;
      },
    },
  ];

  const getRowCount = (gridApi: GridApi) => {
    let count = 0;
    gridApi.forEachNodeAfterFilter(() => count++);
    return count;
  };

  const rowData = useMemo(() => {
    var roles = assignedRoles;
    if (statusFilter.length > 0) {
      roles = assignedRoles.filter((e) => {
        return statusFilter.includes(e.complianceStatus);
      });
    }
    return roles.map((el) => {
      return {
        ...el,
        workerName: `${el.firstNames} ${el.lastName}`,
      };
    });
  }, [assignedRoles, statusFilter]);

  return (
    <>
      {selectedAssignedRoles.length > 0 && showMultipleModal && (
        <UnassignMultipleModal
          assignedRoles={selectedAssignedRoles}
          onClose={() => {
            setShowMultipleModal(false);
          }}
          show={showMultipleModal}
          onConfirm={handleUnassign}
        />
      )}
      {selectedAssignedRole && showSingleModal && (
        <UnassignSingleModal
          assignedRole={selectedAssignedRole}
          onClose={() => {
            setShowSingleModal(false);
          }}
          show={showSingleModal}
          onConfirm={handleUnassign}
        />
      )}

      <Grid container spacing={6}>
        <Grid item xs={8} display={"flex"} alignItems={"end"}>
          <Typography variant="h2">{`Worker roles (${tableLength})`}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent style={{ paddingBottom: 16 }}>
              <Typography variant="h6">Filter by status</Typography>
              <Autocomplete
                multiple
                id="multiple-compliance-status"
                options={["Accredited", "At risk", "Not accredited"]}
                value={statusFilter.map((el) => mapRoleComplianceStatus(el))}
                onChange={async (event, value) => {
                  setStatusFilter(
                    value.map((el) => reverseMapRoleComplianceStatus(el))
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} height={"60vh"}>
                  {(assignedRolesStatus === LoadingStatus.idle ||
                    (assignedRolesStatus === LoadingStatus.loading &&
                      assignedRoles.length === 0)) && <Loader />}
                  {assignedRolesStatus === LoadingStatus.failed && (
                    <ErrorComponent />
                  )}
                  {(assignedRolesStatus === LoadingStatus.succeeded ||
                    assignedRoles.length > 0) && (
                    <AgGridTable
                      data={rowData}
                      columns={columns}
                      dataCyTag="assignedRolesTable"
                      pagination={false}
                      search={{
                        enabled: true,
                      }}
                      gridOptions={{
                        onFilterChanged: (event) => {
                          setTableLength(getRowCount(event.api));
                        },
                        onFirstDataRendered: (event) => {
                          setTableLength(getRowCount(event.api));
                          event.api.forEachNode((node) =>
                            node.setSelected(
                              !!node.data &&
                                selectedAssignedRoles
                                  .map((el) => `${el.id}${el.userId}`)
                                  .includes(
                                    `${node.data.id}${node.data.userId}`
                                  )
                            )
                          );
                        },
                        onModelUpdated: (event) => {
                          setTableLength(getRowCount(event.api));
                        },
                        rowSelection: "multiple",
                        onRowSelected: (
                          event: RowSelectedEvent<AssignedRole, any>
                        ) => {
                          setSelectedAssignedRoles(event.api.getSelectedRows());
                        },
                      }}
                      groupDefaultExpanded={0}
                      masterDetail={true}
                      detailCellRenderer={detailCellRenderer}
                      toolbarElements={[
                        <>
                          <Typography variant="subtitle2" component="h6" m={2}>
                            {`${selectedAssignedRoles.length} row${
                              selectedAssignedRoles.length !== 1 ? "s" : ""
                            } selected`}
                          </Typography>
                        </>,
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (selectedAssignedRoles.length === 1) {
                              setSelectedAssignedRole(selectedAssignedRoles[0]);
                              setShowSingleModal(true);
                              return;
                            }
                            setShowMultipleModal(true);
                          }}
                          disabled={selectedAssignedRoles.length === 0}
                        >
                          Unassign
                        </Button>,
                      ]}
                      menu={{
                        menuItems: (row) => [
                          {
                            label: "Unassign",
                            onClick: () => {
                              setSelectedAssignedRole(row);
                              setShowSingleModal(true);
                            },
                            testingClassName: "unassign",
                          },
                        ],
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default WorkerRoles;
