import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { ChallengeShort } from "../models/challengeShort";
import {
  GetChallengesRequest,
  GetOutstandingChallengesRequest,
} from "../slices/challengesSlice";

type GetChallengesResponse = {
  data: ChallengeShort[];
  meta: ApiResponseMeta;
};

async function getOutstandingSubmissions(
  request: GetOutstandingChallengesRequest
): Promise<AxiosPromise<GetChallengesResponse>> {
  return await axios.post(
    `challenges/v1/query-outstanding-submissions`,
    request
  );
}

async function getOutstandingReviews(
  request: GetOutstandingChallengesRequest
): Promise<AxiosPromise<GetChallengesResponse>> {
  return await axios.post(`challenges/v1/query-outstanding-reviews`, request);
}

async function getChallenges(
  request: GetChallengesRequest
): Promise<AxiosPromise<GetChallengesResponse>> {
  return await axios.post(`challenges/v1/query`, request);
}

export { getOutstandingSubmissions, getOutstandingReviews, getChallenges };
