import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";

async function getUserSummary(
  userId: string,
  orgId: string,
  timeRange: string
): Promise<AxiosPromise> {
  const body = {
    userId: userId,
    orgId: orgId,
    timeRange: timeRange,
  };

  return await axios.post(`stats/users/v1/summary`, body);
}

export { getUserSummary };
