import { useSelector } from "react-redux";
import { selectUser } from "../slices/userSlice";
import useAuth from "../../../hooks/useAuth";

export const useCurrentUser = () => {
  return useSelector(selectUser);
};

export const useCurrentOrg = () => {
  const currentUser = useCurrentUser();
  const { currentOrgId } = useAuth();

  if (!currentUser || !currentOrgId) {
    return undefined;
  }

  return currentUser?.orgs.find((element) => element.id === currentOrgId);
};
