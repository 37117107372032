import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import { Enrolment } from "../models/enrolment";
import { QueryEnrolmentRequest } from "../slices/learningEnrolmentSlice";
import { EnrolmentStructure } from "../models/courseUnit";

type QueryEnrolmentResponse = {
  data: Enrolment[];
  meta: ApiResponseMeta;
};

async function queryEnrolmentsAsync(
  request: QueryEnrolmentRequest
): Promise<AxiosPromise<QueryEnrolmentResponse>> {
  return await axios.post(`/learning/v2/enrolments/query`, request);
}

type GetEnrolmentResponse = {
  data: Enrolment;
  meta: ApiResponseMeta;
};

async function getEnrolmentAsync(
  id: string
): Promise<AxiosPromise<GetEnrolmentResponse>> {
  return await axios.get(`/learning/v2/enrolments/${id}`);
}

type GetEnrolmentStructureResponse = {
  data: EnrolmentStructure;
  meta: ApiResponseMeta;
};

async function getEnrolmentStructureAsync(
  enrolmentId: string
): Promise<AxiosPromise<GetEnrolmentStructureResponse>> {
  return await axios.post(`/learning/v2/enrolments/${enrolmentId}/structure`);
}

export { queryEnrolmentsAsync, getEnrolmentAsync, getEnrolmentStructureAsync };
