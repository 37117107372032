import { UserOrganisation } from "../features/user/models/user";

export const getHomePagePath = (
  isAuthenticated: boolean,
  currentOrg?: UserOrganisation
) => {
  if (!isAuthenticated) {
    return "/auth/sign-in";
  }
  if (currentOrg?.capabilities && currentOrg.capabilities.length > 0) {
    if (currentOrg?.capabilities.includes("checkIns")) {
      return "/check-ins/dashboard";
    }
    if (currentOrg?.capabilities.includes("accreditations")) {
      return "/compliance/workforce";
    }
  }
  return "/download-app";
};
