export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
} as {
  region: string;
  userPoolId: string;
  clientId: string;
  identityPoolId: string;
};

export const visiblyApiConfig = {
  baseUrl: process.env.REACT_APP_VISIBLY_API_BASE_URL,
};

export const visiblyMobileAppConfig = {
  appStoreUrl: process.env.REACT_APP_VISIBLY_APP_STORE_URL,
  playStoreUrl: process.env.REACT_APP_VISIBLY_PLAY_STORE_URL,
};

export const s3Config = {
  level: process.env.REACT_APP_VISIBLY_S3_FILE_STORAGE_LEVEL,
  region: process.env.REACT_APP_VISIBLY_S3_FILE_STORAGE_REGION,
  bucket: process.env.REACT_APP_VISIBLY_S3_FILE_STORAGE_BUCKET,
  externalPartnerBucket:
    process.env.REACT_APP_VISIBLY_S3_EXTERNAL_PARTNER_ASSETS_STORAGE_BUCKET,
  identityPoolId:
    process.env.REACT_APP_VISIBLY_S3_FILE_STORAGE_IDENTITY_POOL_ID,
};

export const intercomConfig = {
  appId: process.env.REACT_APP_INTERCOM_APP_ID,
};

export const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  version: process.env.REACT_APP_SENTRY_VERSION,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  enabled: process.env.REACT_APP_SENTRY_ENABLED,
};
