import { lazy } from "react";
import LogoFormLayout from "../layouts/LogoForm";
import { RouteObject } from "react-router-dom";

const CreateOrganisation = lazy(
  () => import("../features/organisation/pages/CreateOrganisation")
);

const ChooseOrganisation = lazy(
  () => import("../features/organisation/pages/chooseOrganisation")
);

export const organisationRoutes = [
  {
    path: "organisation",
    element: <LogoFormLayout />,
    children: [
      {
        path: "create",
        element: <CreateOrganisation />,
      },
      {
        path: "choose",
        element: <ChooseOrganisation />,
      },
    ],
  },
] as RouteObject[];
