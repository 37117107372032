import React from "react";
import { Helmet } from "react-helmet-async";
import SignInForm from "./SignInForm";
import styled from "@emotion/styled";
import {
  Brand,
  Wrapper as MuiWrapper,
} from "../../../../components/layout/Common";

const Wrapper = styled(MuiWrapper)`
  width: 100%;
  max-width: 580px;
`;

function SignIn() {
  return (
    <>
      <Helmet title="Sign In" data-cy="signInPage" />
      <Brand />
      <Wrapper>
        <SignInForm />
      </Wrapper>
    </>
  );
}

export default SignIn;
