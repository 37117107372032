import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MixedTaskContent, TaskSpec } from "../models/taskSpec";
import {
  ReviewType,
  TaskSpecCadence,
  TaskType,
  TaskVisibility,
} from "../models/taskSpecEnums";
import { LoadingStatus } from "../../../models/loadingStatus";
import * as taskSpecService from "../services/taskSpecService";

export type GetTaskSpecsRequest = {
  orgId: string;
  searchString?: string;
};

export const getTaskSpecs = createAsyncThunk(
  "tasks/setTasks",
  async ({ orgId, searchString }: GetTaskSpecsRequest, { rejectWithValue }) => {
    try {
      return (
        await taskSpecService.getTaskSpecs({
          orgId: orgId,
          searchString: searchString,
        })
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getTaskSpec = createAsyncThunk(
  "tasks/getTaskSpec",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await taskSpecService.getTaskSpec(id)).data;
    } catch (err: any) {
      return rejectWithValue({
        error: err,
      });
    }
  }
);

export type UpdateTaskSpecRequest = {
  baseVersion: number;
  name?: string;
  taskType?: TaskType;
  headline?: string;
  cadence?: TaskSpecCadence;
  content?: MixedTaskContent;
  defaultRequiredReviewCount?: number;
  defaultReviewWindowInHours?: number;
  visibility?: TaskVisibility;
  defaultReviewType?: ReviewType;
  id: string;
};

interface TaskSpecState {
  taskSpecs: TaskSpec[];
  taskSpec?: TaskSpec;
  status: LoadingStatus;
  getTaskSpecStatus: LoadingStatus;
  error: string;
}

const initialState: TaskSpecState = {
  taskSpecs: [],
  status: LoadingStatus.idle,
  getTaskSpecStatus: LoadingStatus.idle,
  error: "",
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaskSpecs.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpecs.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSpecs: action.payload.data,
      };
    });

    builder.addCase(getTaskSpecs.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        taskSpecs: [],
      };
    });

    builder.addCase(getTaskSpec.pending, (state, action) => {
      return {
        ...state,
        getTaskSpecStatus: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpec.fulfilled, (state, action) => {
      return {
        ...state,
        getTaskSpecStatus: LoadingStatus.succeeded,
        taskSpec: action.payload.data,
      };
    });
    builder.addCase(getTaskSpec.rejected, (state, action) => {
      return {
        ...state,
        getTaskSpecStatus: LoadingStatus.failed,
      };
    });
  },
});

export default tasksSlice.reducer;
