import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { HealthCheck } from "../models/healthCheck";

type HealthCheckResponse = {
  data: HealthCheck;
  meta: ApiResponseMeta;
};

export async function getHealthCheck(
  orgId: string
): Promise<AxiosPromise<HealthCheckResponse>> {
  return await axios.post(`/orgs/v1/healthcheck/${orgId}`);
}
