import { AxiosPromise } from "axios";
import axios from "../../../../utils/axios";
import { ApiResponseMeta } from "../../../../models/apiResponseMeta";
import { Group } from "../../../models/group";
import { GroupWithRoles } from "../models/groups";
import { CreateGroupRequest, QueryGroupsRequest } from "../slices/groupsSlice";

type CreateGroupResponse = {
  meta: ApiResponseMeta;
};

export async function createGroup(
  request: CreateGroupRequest
): Promise<AxiosPromise<CreateGroupResponse>> {
  return await axios.post(`compliance/groups/v1`, request);
}

type GetGroupResponse = {
  meta: ApiResponseMeta;
  data: GroupWithRoles;
};

export async function getGroup(
  groupId: string
): Promise<AxiosPromise<GetGroupResponse>> {
  return await axios.get(`compliance/groups/v1/${groupId}`);
}

type QueryGroupsResponse = {
  meta: ApiResponseMeta;
  data: Group[];
};

export async function queryGroups(
  request: QueryGroupsRequest
): Promise<AxiosPromise<QueryGroupsResponse>> {
  return await axios.post(`compliance/groups/v1/query`, request);
}
