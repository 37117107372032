import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import {
  CourseEnrolmentStat,
  CourseActivity,
  CourseUnitActivity,
} from "../models/learningStats";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import {
  GetCourseEnrolmentsRequest,
  GetCourseActivitiesRequest,
  GetCourseUnitActivitiesRequest,
} from "../slices/learningStatsSlice";

type GetCourseActivitiesResponse = {
  data: CourseActivity[];
  meta: ApiResponseMeta;
};

async function getCourseActivitiesAsync(
  request: GetCourseActivitiesRequest
): Promise<AxiosPromise<GetCourseActivitiesResponse>> {
  return await axios.post(`/stats/learning/v2/course-activity`, request);
}

type GetCourseUnitActivitiesResponse = {
  data: CourseUnitActivity[];
  meta: ApiResponseMeta;
};

async function getCourseUnitActivitiesAsync(
  request: GetCourseUnitActivitiesRequest
): Promise<AxiosPromise<GetCourseUnitActivitiesResponse>> {
  return await axios.post(`/stats/learning/v2/course-unit-activity`, request);
}

type GetCourseEnrolmentsResponse = {
  data: CourseEnrolmentStat[];
  meta: ApiResponseMeta;
};

async function getCourseEnrolmentsAsync(
  request: GetCourseEnrolmentsRequest
): Promise<AxiosPromise<GetCourseEnrolmentsResponse>> {
  return await axios.post(`/stats/learning/v2/course-enrolments`, request);
}

export {
  getCourseActivitiesAsync,
  getCourseUnitActivitiesAsync,
  getCourseEnrolmentsAsync,
};
