import { AxiosResponse } from "axios";
import axios from "../../../utils/axios";
import {
  EventSubscription,
  EventSubscriptionOptions,
  EventSubscriptionType,
  EventSubscriptionApiResponse as EventSubscriptionApiShortResponse,
} from "../models/eventSubscriptions";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

export type EventSubscriptionsQueryRequest = {
  orgId: string;
  teamId?: string;
  userId?: string;
  event?: EventSubscriptionType;
};

export type EventSubscriptionResponse = {
  data: EventSubscription[];
  meta: ApiResponseMeta;
};

export type EventSubscriptionCreateRequest = {
  orgId: string;
  teamId: string;
  event: EventSubscriptionType;
  options: EventSubscriptionOptions;
  communicationGroupId?: string;
};

export type EventSubscriptionUpdateRequest = {
  id: string;
  orgId: string;
  teamId?: string;
  userId?: string;
  event?: EventSubscriptionType;
  options?: EventSubscriptionOptions;
  communicationGroupId?: string;
};

export type EventSubscriptionCreateResponse = {
  data: EventSubscriptionApiShortResponse;
  meta: ApiResponseMeta;
};

export type EventSubscriptionUpdateResponse = {
  data: EventSubscriptionApiShortResponse;
  meta: ApiResponseMeta;
};

export type EventSubscriptionGetResponse = {
  data: EventSubscription;
  meta: ApiResponseMeta;
};

async function queryEventSubscriptions(
  request: EventSubscriptionsQueryRequest
): Promise<AxiosResponse<EventSubscriptionResponse>> {
  return await axios.post(`/teams/v1/event-subscriptions/query`, request);
}

async function deleteEventSubscription(
  eventSubscriptionId: string
): Promise<AxiosResponse> {
  return await axios.delete(
    `/teams/v1/event-subscriptions/${eventSubscriptionId}`
  );
}

async function getEventSubscription(
  eventSubscriptionId: string
): Promise<AxiosResponse<EventSubscriptionGetResponse>> {
  return await axios.get(
    `/teams/v1/event-subscriptions/${eventSubscriptionId}`
  );
}

async function createEventSubscription(
  request: EventSubscriptionCreateRequest
): Promise<AxiosResponse<EventSubscriptionCreateResponse>> {
  return await axios.post(`/teams/v1/event-subscriptions`, request);
}

async function updateEventSubscription(
  eventSubscriptionId: string,
  request: EventSubscriptionUpdateRequest
): Promise<AxiosResponse<EventSubscriptionUpdateResponse>> {
  return await axios.put(
    `/teams/v1/event-subscriptions/${eventSubscriptionId}`,
    request
  );
}

export {
  queryEventSubscriptions,
  deleteEventSubscription,
  createEventSubscription,
  getEventSubscription,
  updateEventSubscription,
};
