import { Users } from "react-feather";
import { SidebarItemsType } from "../../../types/sidebar";

export const workforceItem = {
  href: "/compliance/workforce",
  icon: Users,
  title: () => "Workforce",
  innerNavigationItems: [
    {
      title: "Dashboard",
      to: (_) => "/compliance/workforce/dashboard",
      disabled: true,
    },
    {
      title: "Project roles",
      to: (_) => "/compliance/workforce/project-roles",
      disabled: true,
    },
    {
      title: "Workers",
      to: (_) => "/compliance/workforce/workers",
    },
    {
      title: "Worker roles",
      to: (_) => "/compliance/workforce/worker-roles",
      disabled: true,
    },
    {
      title: "Accreditations",
      to: (_) => "/compliance/workforce/accreditations",
    },
  ],
  children: [
    {
      title: (workerName: string) => `${workerName ?? ""}`,
      hasBackButton: true,
      backButtonTo: (_) => `/compliance/workforce/workers`,
      type: "worker",
      innerNavigationItems: [
        {
          title: "Details",
          to: (_) => `/compliance/workforce/workers/${_.workerId}/details`,
        },
        {
          title: "Worker roles",
          to: (_) => `/compliance/workforce/workers/${_.workerId}/worker-roles`,
        },
        {
          title: "Accreditations",
          to: (_) =>
            `/compliance/workforce/workers/${_.workerId}/accreditations`,
        },
      ],
    },
  ],
} as SidebarItemsType;
