import { AxiosPromise } from "axios";
import { TaskSpecSummary } from "../models/taskSpecSummary";
import { Rate, ReviewRating } from "../models/dailyRates";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { TaskSpecUserPerformance } from "../models/taskSpecUserPerformance";

type GetTaskSpecActivityResponse = {
  data: TaskSpecSummary[];
  meta: {};
};

async function getTaskSpecActivity(
  orgId: string,
  dayInterval: number,
  userId?: string
): Promise<AxiosPromise<GetTaskSpecActivityResponse>> {
  const body = {
    orgId: orgId,
    timeRange: `${dayInterval}d`,
    userId: userId,
  };

  return await axios.post(`stats/challenges/v1/task-spec-activity`, body);
}

type DailyCompletionRatesResponse = {
  data: Rate[];
  meta: ApiResponseMeta;
};

async function getDailyChallengeCompletionRates(
  orgId: string,
  dayInterval: number
): Promise<AxiosPromise<DailyCompletionRatesResponse>> {
  const body = {
    orgId: orgId,
    timeRange: dayInterval + "d",
  };

  return await axios.post(`stats/challenges/v1/completion`, body);
}

async function getDailyReviewCompletionRates(
  orgId: string,
  dayInterval: number
): Promise<AxiosPromise<DailyCompletionRatesResponse>> {
  const body = {
    orgId: orgId,
    timeRange: dayInterval + "d",
  };

  return await axios.post(`stats/challenges/v1/review-completion`, body);
}

type DailyReviewRatingsResponse = {
  data: ReviewRating[];
  meta: ApiResponseMeta;
};

async function getDailyReviewRatingsQuery(
  orgId: string,
  dayInterval: number,
  userId?: string
): Promise<AxiosPromise<DailyReviewRatingsResponse>> {
  const body = {
    orgId: orgId,
    timeRange: dayInterval + "d",
    userId: userId,
  };

  return await axios.post(`stats/challenges/v1/review-ratings`, body);
}

type TaskSpecComponentActivityResponse = {
  data: TaskSpecSummary[];
  meta: ApiResponseMeta;
};

async function getTaskSpecComponentActivity(
  orgId: string,
  dayInterval: number,
  taskSpecId: string
): Promise<AxiosPromise<TaskSpecComponentActivityResponse>> {
  const body = {
    orgId: orgId,
    timeRange: `${dayInterval}d`,
    taskSpecId: taskSpecId,
  };

  return await axios.post(
    `stats/challenges/v1/task-spec-activity-by-component`,
    body
  );
}

async function getReviewerActivityQuery(
  orgId: string,
  dayInterval: number,
  teamId?: string
): Promise<AxiosPromise> {
  const body = {
    orgId: orgId,
    timeRange: `${dayInterval}d`,
    teamId: teamId,
  };

  return await axios.post(`stats/challenges/v1/reviewer-activity`, body);
}

async function getChallengerActivityQuery(
  orgId: string,
  dayInterval: number,
  teamId?: string
): Promise<AxiosPromise> {
  const body = {
    orgId: orgId,
    timeRange: `${dayInterval}d`,
    teamId: teamId,
  };

  return await axios.post(`stats/challenges/v1/challenger-activity`, body);
}

type GetTaskSpecUserPerformanceResponse = {
  data: TaskSpecUserPerformance[];
  meta: ApiResponseMeta;
};

async function getTaskSpecUserPerformance(
  orgId: string,
  dayInterval: number
): Promise<AxiosPromise<GetTaskSpecUserPerformanceResponse>> {
  const body = {
    orgId: orgId,
    timeRange: `${dayInterval}d`,
  };

  return await axios.post(
    `stats/challenges/v1/task-spec-user-performance`,
    body
  );
}

async function getChallengesWithReviewDiscrepancies(
  orgId: string,
  date: Date,
  teamId?: string
) {
  const body = {
    orgId: orgId,
    date: date,
    teamId: teamId,
  };

  return await axios.post(`stats/challenges/v1/review-discrepancy-date`, body);
}

async function getReviewDiscrepancies(
  orgId: string,
  dayInterval: number,
  teamId?: string
) {
  const body = {
    orgId: orgId,
    timeRange: `${dayInterval}d`,
    teamId: teamId,
  };

  return await axios.post(`stats/challenges/v1/review-discrepancy`, body);
}

export {
  getTaskSpecActivity,
  getDailyChallengeCompletionRates,
  getDailyReviewCompletionRates,
  getDailyReviewRatingsQuery,
  getTaskSpecComponentActivity,
  getReviewerActivityQuery,
  getChallengerActivityQuery,
  getTaskSpecUserPerformance,
  getChallengesWithReviewDiscrepancies,
  getReviewDiscrepancies,
};
