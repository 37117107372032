import {
  ContactInfo,
  OrganisationMember,
} from "../../../models/organisationMember";
import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";

export type GetOrganisationMemberDetailsRequest = {
  orgId: string;
  userId: string;
};

export type GetOrganisationMemberDetailsResponse = {
  data: OrganisationMember;
  meta: any;
};

async function getOrganisationMemberDetailsAsync(
  request: GetOrganisationMemberDetailsRequest
): Promise<AxiosPromise<GetOrganisationMemberDetailsResponse>> {
  return await axios.get(`orgs/v1/${request.orgId}/members/${request.userId}`);
}

export type UpdateOrganisationMemberRequest = {
  contactInfo: ContactInfo;
};

export type UpdateOrganisationMemberResponse = {
  meta: any;
};

async function updateOrganisationMemberAsync(
  orgId: string,
  userId: string,
  request: UpdateOrganisationMemberRequest
): Promise<AxiosPromise<UpdateOrganisationMemberResponse>> {
  return await axios.put(`orgs/v1/${orgId}/members/${userId}`, request);
}

export { getOrganisationMemberDetailsAsync, updateOrganisationMemberAsync };
