import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import {
  CommunicationGroup,
  CommunicationGroupVisibility,
  CommunicationGroupWithCommunications,
} from "../models/communicationGroups";
import { Communication } from "../models/communication";
import axios from "../../../utils/axios";

export type QueryCommunicationGroupsRequest = {
  orgId: string;
};

type QueryCommunicationGroupsResponse = {
  data: CommunicationGroup[];
  meta: ApiResponseMeta;
};

async function queryCommunicationGroupsAsync(
  body: QueryCommunicationGroupsRequest
): Promise<AxiosPromise<QueryCommunicationGroupsResponse>> {
  return await axios.post("/communications/v1/groups/query", body);
}

type GetCommunicationGroupResponse = {
  data: CommunicationGroupWithCommunications;
  meta: ApiResponseMeta;
};

async function getCommunicationGroupAsync(
  id: string
): Promise<AxiosPromise<GetCommunicationGroupResponse>> {
  return await axios.get(`/communications/v1/groups/${id}`);
}

export type CreateCommunicationGroupRequest = {
  orgId: string;
  name: string;
  description: string;
  communications: Communication[];
  visibility: CommunicationGroupVisibility;
};

type CreateCommunicationGroupResponse = {
  meta: ApiResponseMeta;
};

async function createCommunicationGroupAsync(
  body: CreateCommunicationGroupRequest
): Promise<AxiosPromise<CreateCommunicationGroupResponse>> {
  return await axios.post("/communications/v1/groups", body);
}

export type UpdateCommunicationGroupRequest = {
  orgId: string;
  name?: string;
  description?: string;
  communications?: Communication[];
  visibility?: CommunicationGroupVisibility;
  isActive?: boolean;
};

type UpdateCommunicationGroupResponse = {
  meta: ApiResponseMeta;
};

async function updateCommunicationGroupAsync(
  id: string,
  body: UpdateCommunicationGroupRequest
): Promise<AxiosPromise<UpdateCommunicationGroupResponse>> {
  return await axios.put(`/communications/v1/groups/${id}`, body);
}

export {
  queryCommunicationGroupsAsync,
  getCommunicationGroupAsync,
  createCommunicationGroupAsync,
  updateCommunicationGroupAsync,
};
