import {
  ICellRendererParams,
  IDetailCellRendererParams,
} from "ag-grid-enterprise";
import { UserRoleCompliance } from "../common/models/accreditationUser";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { mapAccreditationStatus } from "../features/groups/utils/accreditationUtils";
import StatusDot from "./StatusDot";
import AgGridTable from "../../components/AgGridTable";
import { Fragment } from "react";
import { Accreditation } from "../features/workforce/models/accreditation";

function AccreditationDetailCellRenderer({
  data,
}: IDetailCellRendererParams<UserRoleCompliance>) {
  return (
    <Box
      overflow={"auto"}
      height={"100%"}
      style={{ backgroundColor: "#f2f1f1" }}
      padding={5}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" style={{ marginLeft: 10 }}>
            All Required
          </Typography>
        </Grid>
        <Grid item xs={12} height={"fit-content"} mt={2}>
          <AccreditationTable
            data={data?.mandatoryAccreditations ?? []}
            id={"mandatory"}
          />
        </Grid>
        {data?.interchangeableAccreditations.map((el) => {
          return (
            <Fragment key={el.map((el) => el.accreditationSpecId).join("_")}>
              <Grid item xs={12} mt={5}>
                <Typography variant="subtitle2" style={{ marginLeft: 10 }}>
                  At least 1 required
                </Typography>
              </Grid>
              <Grid item xs={12} height={"fit-content"} mt={2}>
                <AccreditationTable
                  data={el}
                  id={`interchangeable_${el[0].id}`}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
}

type AccreditationTableProps = {
  data: Accreditation[];
  id: string;
};

function AccreditationTable(props: AccreditationTableProps) {
  return (
    <AgGridTable
      data={props.data}
      autoHeight
      columns={[
        {
          field: "name",
          headerName: "Accreditation",
          sortable: true,
          flex: 0.5,
          tooltipField: "name",
        },
        {
          field: "code",
          headerName: "Code",
          sortable: true,
          flex: 0.5,
        },
        {
          field: "status",
          headerName: "Status",
          sortable: true,
          flex: 0.5,
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <>
                <StatusDot
                  marginBottom={2}
                  colour={
                    params.value === "expired" || params.value === "notHeld"
                      ? "red"
                      : params.value === "expiring"
                      ? "amber"
                      : "green"
                  }
                />
                <p>{mapAccreditationStatus(params.value)}</p>
              </>
            );
          },
        },
        {
          field: "expiresAt",
          headerName: "Expiry date",
          sortable: true,
          flex: 0.5,
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value) {
              return format(new Date(params.value), "dd/MM/yyyy");
            }
            return "-";
          },
        },
      ]}
      dataCyTag={`accreditationTable_${props.id}`}
      pagination={false}
      paginationAutoPageSize
    />
  );
}

export default AccreditationDetailCellRenderer;
