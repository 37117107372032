import { Navigate, RouteObject } from "react-router-dom";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";
import VisiblyOSLayout from "../visiblyOS/VisiblyOSLayout";
import { lazy } from "react";

const CheckIns = lazy(() => import("../features/checkIns/pages/checkins"));

const CheckInsDashboard = lazy(
  () => import("../features/checkIns/pages/dashboard")
);

const CheckInsUserActivity = lazy(
  () => import("../features/checkIns/pages/analytics")
);

const UserActivityInstancesList = lazy(
  () => import("../features/checkIns/pages/analytics/UserActivityInstancesList")
);

const UserActivityDetails = lazy(
  () => import("../features/checkIns/pages/analytics/UserActivityDetails")
);

export const checkInsRoutes = [
  {
    path: "check-ins",
    element: (
      <CanViewCapabilityRouteGuard capability="checkIns">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/check-ins/dashboard" />,
      },
      {
        path: "dashboard",
        element: <CheckInsDashboard />,
      },
      {
        path: "checks",
        element: <CheckIns />,
      },
      {
        path: "analytics",
        element: <Navigate to="/check-ins/analytics/user-activity" />,
      },
      {
        path: "analytics/user-activity",
        element: <CheckInsUserActivity />,
      },
      {
        path: "analytics/user-activity-instances/:id",
        element: <UserActivityInstancesList />,
      },
      {
        path: "analytics/user-activity-details/:id",
        element: <UserActivityDetails />,
      },
    ],
  },
] as RouteObject[];
