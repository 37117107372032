import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrganisationMembers } from "../../users/services/organisationMembersService";
import { OrganisationMember } from "../../../models/organisationMember";
import { LoadingStatus } from "../../../models/loadingStatus";

interface TeamMembersState {
  teamMembers: OrganisationMember[];
  status: LoadingStatus;
  error: string;
}

const initialState: TeamMembersState = {
  teamMembers: [],
  status: LoadingStatus.idle,
  error: "",
};

export type GetTeamMembersRequest = {
  orgId: string;
  teamId: string;
  searchString?: string;
};

export const getTeamMembers = createAsyncThunk(
  "teams/setTeamMembers",
  async (request: GetTeamMembersRequest, { rejectWithValue }) => {
    try {
      return (
        await getOrganisationMembers({
          orgId: request.orgId,
          searchString: request.searchString,
          teamId: request.teamId,
          active: true,
        })
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {
    clearCurrentTeamMembers: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeamMembers.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTeamMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teamMembers: action.payload.data,
      };
    });
    builder.addCase(getTeamMembers.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed, teamMembers: [] };
    });
  },
});

export const { clearCurrentTeamMembers } = teamMembersSlice.actions;
export default teamMembersSlice.reducer;
