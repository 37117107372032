import VisiblyAdminGuard from "../components/guards/VisiblyAdminGuard";
import VisiblyOSLayout from "../visiblyOS/VisiblyOSLayout";
import InternalCreateOrganisation from "../features/users/pages/CreateOrganisation";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CreateInvite = lazy(() => import("../features/users/pages/CreateInvite"));

export const internalAdminRoutes = [
  {
    path: "internal-admin",
    element: (
      <VisiblyAdminGuard>
        <VisiblyOSLayout />
      </VisiblyAdminGuard>
    ),
    children: [
      {
        path: "create-organisation",
        element: <InternalCreateOrganisation />,
      },
      {
        path: "invite-user",
        element: <CreateInvite />,
      },
    ],
  },
] as RouteObject[];
