import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { OrganisationMember } from "../models/organisationMember";
import * as organisationMembersService from "../services/organisationMembersService";

export type QueryOrganisationMembersRequest = {
  orgId: string;
  includeUnSignedUp: boolean;
};

export const queryOrganisationMembers = createAsyncThunk(
  "organisationMember/queryOrganisationMembers",
  async (request: QueryOrganisationMembersRequest, { rejectWithValue }) => {
    try {
      return (
        await organisationMembersService.queryOrganisationMembers(request)
      ).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type OrganisationMemberState = {
  status: LoadingStatus;
  organisationMembers: OrganisationMember[];
  error: string | null;
};

const initialState: OrganisationMemberState = {
  status: LoadingStatus.idle,
  organisationMembers: [],
  error: null,
};

const organisationMemberSlice = createSlice({
  name: "organisationMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryOrganisationMembers.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryOrganisationMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationMembers: action.payload.data,
      };
    });
    builder.addCase(queryOrganisationMembers.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export default organisationMemberSlice.reducer;
