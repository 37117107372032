import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import {
  Button as MuiButton,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import useAuth from "../../../../hooks/useAuth";
import useAppSelector from "../../../../hooks/useAppSelector";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { getPublicInvite } from "../../slices/publicInviteSlice";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignInForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get("inviteId");
  const publicInvite = useAppSelector(
    (state) => state.publicInvite.publicInvite
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (inviteId) {
      dispatch(getPublicInvite(inviteId)).unwrap();
    }
  }, [inviteId, dispatch]);

  return (
    <>
      {publicInvite && !publicInvite.accepted && (
        <Grid container textAlign="center" mb={5}>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1" align="center">
              <b>{publicInvite.inviter}</b> invited you to join
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              <b>{publicInvite.organisationName}</b> organisation
            </Typography>
          </Grid>
        </Grid>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().max(255).required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            await signIn(values.email, values.password);
            if (!inviteId) {
              const isCreatingOrg =
                localStorage.getItem("isCreatingOrg") === "true";
              if (isCreatingOrg) {
                localStorage.removeItem("isCreatingOrg");
                navigate("/organisation/create");
                return;
              }
            } else {
              navigate(`/accept-invite/${inviteId}`);
            }
          } catch (error: any) {
            let message = error.message || "Something went wrong";
            message = message.replace(
              "User is disabled",
              "User does not exist"
            );

            setStatus({ success: false });
            setSubmitting(false);
            const snackbarKey = enqueueSnackbar(message, {
              variant: "error",
              onClick: () => closeSnackbar(snackbarKey),
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Typography
              component="h1"
              variant="subtitle2"
              pl={4}
              fontWeight="bold"
            >
              Email
            </Typography>
            <TextField
              type="email"
              name="email"
              data-cy="emailField"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Typography
              component="h1"
              variant="subtitle2"
              pl={4}
              mt={2}
              fontWeight="bold"
            >
              Password
            </Typography>
            <TextField
              type="password"
              name="password"
              data-cy="passwordField"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Button
              type="submit"
              data-cy="loginButton"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              mt={4}
              p={4}
            >
              Log in
            </Button>
            <Grid container>
              <Grid item xs={12} textAlign="center">
                <Button
                  href="/auth/forgot-password"
                  color="primary"
                  mt={4}
                  size="large"
                  data-cy="resetPasswordButton"
                >
                  Reset password
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
}

export default SignInForm;
