import useAuth from "../../../hooks/useAuth";

interface VisiblyAdminNavItemGuardProps {
  children?: React.ReactNode;
}

const VisiblyAdminNavItemGuard = ({
  children,
}: VisiblyAdminNavItemGuardProps) => {
  const { isVisiblyAdmin, isVisiblySupport } = useAuth();

  if (!isVisiblyAdmin && !isVisiblySupport) {
    return <></>;
  }

  return <>{children}</>;
};

export default VisiblyAdminNavItemGuard;
