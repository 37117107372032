import { AxiosPromise } from "axios";
import axios from "../../../../utils/axios";
import { ApiResponseMeta } from "../../../../models/apiResponseMeta";
import { GroupRole } from "../models/groupRoles";
import {
  AssignRequest,
  QueryGroupRolesRequest,
} from "../slices/groupRolesSlice";
import { AssignedRole } from "../models/assignedRoles";

type QueryGroupRolesResponse = {
  data: GroupRole[];
  meta: ApiResponseMeta;
};

export async function queryGroupRoles(
  groupId: string,
  request: QueryGroupRolesRequest
): Promise<AxiosPromise<QueryGroupRolesResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/query`,
    request
  );
}

type UnassignResponse = {
  meta: ApiResponseMeta;
};

export async function bulkAssign(
  groupId: string,
  roleId: string,
  request: AssignRequest
): Promise<AxiosPromise<UnassignResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/${roleId}/bulk-assign`,
    request
  );
}

type QueryAssignedRolesResponse = {
  data: AssignedRole[];
  meta: ApiResponseMeta;
};

export async function queryAssignedRoles(
  groupId: string,
  request: QueryGroupRolesRequest
): Promise<AxiosPromise<QueryAssignedRolesResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/query-assigned`,
    request
  );
}

export async function unassign(
  groupId: string,
  roleId: string,
  request: AssignRequest
): Promise<AxiosPromise<UnassignResponse>> {
  return await axios.post(
    `compliance/groups/v1/${groupId}/roles/${roleId}/unassign`,
    request
  );
}
