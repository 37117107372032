import React from "react";
import { Helmet } from "react-helmet-async";
import { Typography } from "@mui/material";
import { Brand, Wrapper } from "../../../../components/layout/Common";
import ForgotPasswordComponent from "./ForgotPasswordForm";

function ForgotPassword() {
  return (
    <>
      <Brand />
      <Wrapper>
        <Helmet title="Forgot Password" data-cy="forgotPasswordPage" />
        <Typography
          component="h1"
          variant="h4"
          align="center"
          gutterBottom
          data-cy="forgotPasswordHeader"
        >
          Forgot Password
        </Typography>
        <ForgotPasswordComponent />
      </Wrapper>
    </>
  );
}

export default ForgotPassword;
