import { Navigate, RouteObject } from "react-router-dom";
import ExperimentDashboard from "../../features/experiment/ExperimentDashboardLayout";
import { lazy } from "react";

const Projects = lazy(
  () => import("../../features/experiment/pages/projects/Projects")
);

const ProjectsDashboard = lazy(
  () => import("../../features/experiment/pages/projects/Dashboard")
);

const ProjectDashboard = lazy(
  () => import("../../features/experiment/pages/projects/project/Dashboard")
);

const ProjectAccreditations = lazy(
  () =>
    import("../../features/experiment/pages/projects/project/Accreditations")
);

const ProjectWorkerRoles = lazy(
  () => import("../../features/experiment/pages/projects/project/WorkerRoles")
);

const ProjectWorkers = lazy(
  () => import("../../features/experiment/pages/projects/project/Workers")
);

const ProjectProjectRoles = lazy(
  () => import("../../features/experiment/pages/projects/project/ProjectRoles")
);

const ProjectWorkerAccreditations = lazy(
  () =>
    import(
      "../../features/experiment/pages/projects/project/worker/Accreditations"
    )
);

const ProjectWorkerWorkerRoles = lazy(
  () =>
    import(
      "../../features/experiment/pages/projects/project/worker/WorkerRoles"
    )
);

const ProjectWorkerDetails = lazy(
  () =>
    import("../../features/experiment/pages/projects/project/worker/Details")
);

const ProjectContractors = lazy(
  () =>
    import(
      "../../features/experiment/pages/projects/project/contractors/Contractors"
    )
);

export const experimentProjectRoutes = [
  {
    path: "experiment",
    element: <ExperimentDashboard />,
    children: [
      {
        path: "projects",
        element: <Navigate to="/experiment/projects/dashboard" />,
      },
      {
        path: "projects/list",
        element: <Projects />,
      },
      {
        path: "projects/dashboard",
        element: <ProjectsDashboard />,
      },
      {
        path: "projects/:projectId",
        children: [
          {
            path: "dashboard",
            element: <ProjectDashboard />,
          },
          {
            path: "workers",
            element: <ProjectWorkers />,
          },
          {
            path: "worker-roles",
            element: <ProjectWorkerRoles />,
          },
          {
            path: "accreditations",
            element: <ProjectAccreditations />,
          },
          {
            path: "project-roles",
            element: <ProjectProjectRoles />,
          },
          {
            path: "contractors",
            element: <ProjectContractors />,
          },
          {
            path: "workers/:workerId",
            children: [
              {
                path: "details",
                element: <ProjectWorkerDetails />,
              },
              {
                path: "accreditations",
                element: <ProjectWorkerAccreditations />,
              },
              {
                path: "worker-roles",
                element: <ProjectWorkerWorkerRoles />,
              },
            ],
          },
        ],
      },
    ],
  },
] as RouteObject[];
