import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { Activity, CheckInUser } from "../models/activity";
import {
  getActivityAsync,
  queryCheckInUsersAsync,
  QueryCheckInUsersRequest,
  queryUserActivityAsync,
} from "../services/activityService";
import { AuditLog } from "../models/auditLog";

export const getCheckInUsers = createAsyncThunk(
  "activities/getCheckInUsers",
  async (request: QueryCheckInUsersRequest, { rejectWithValue }) => {
    try {
      const result = await queryCheckInUsersAsync(request);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getCheckInUserActivity = createAsyncThunk(
  "activities/getCheckInUserActivity",
  async (request: QueryCheckInUsersRequest, { rejectWithValue }) => {
    try {
      const result = await queryUserActivityAsync(request);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getActivity = createAsyncThunk(
  "activities/getActivity",
  async (activityId: string, { rejectWithValue }) => {
    try {
      const result = await getActivityAsync(activityId);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface ActivityState {
  checkInUsers: CheckInUser[];
  checkInUserActivities: Activity[];
  activity?: Activity;
  auditLog?: AuditLog;
  activitiesStatus: LoadingStatus;
  checkInUsersActivitiesStatus: LoadingStatus;
  activityStatus: LoadingStatus;
  commentActivityStatus: LoadingStatus;
  error: string;
}

const initialState: ActivityState = {
  checkInUsers: [],
  checkInUserActivities: [],
  activitiesStatus: LoadingStatus.idle,
  checkInUsersActivitiesStatus: LoadingStatus.idle,
  activityStatus: LoadingStatus.idle,
  commentActivityStatus: LoadingStatus.idle,
  error: "",
};

const activitySlice = createSlice({
  name: "activities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCheckInUsers.pending, (state, action) => {
      return { ...state, activitiesStatus: LoadingStatus.loading };
    });
    builder.addCase(getCheckInUsers.fulfilled, (state, action) => {
      return {
        ...state,
        activitiesStatus: LoadingStatus.succeeded,
        checkInUsers: action.payload.data,
      };
    });
    builder.addCase(getCheckInUsers.rejected, (state, action) => {
      return {
        ...state,
        activitiesStatus: LoadingStatus.failed,
        checkInUsers: [],
      };
    });

    builder.addCase(getCheckInUserActivity.pending, (state, action) => {
      return { ...state, checkInUsersActivitiesStatus: LoadingStatus.loading };
    });
    builder.addCase(getCheckInUserActivity.fulfilled, (state, action) => {
      return {
        ...state,
        checkInUsersActivitiesStatus: LoadingStatus.succeeded,
        checkInUserActivities: action.payload.data,
      };
    });
    builder.addCase(getCheckInUserActivity.rejected, (state, action) => {
      return {
        ...state,
        checkInUsersActivitiesStatus: LoadingStatus.failed,
        checkInUserActivities: [],
      };
    });

    builder.addCase(getActivity.pending, (state, action) => {
      return { ...state, activityStatus: LoadingStatus.loading };
    });
    builder.addCase(getActivity.fulfilled, (state, action) => {
      return {
        ...state,
        activityStatus: LoadingStatus.succeeded,
        activity: action.payload.data,
      };
    });
    builder.addCase(getActivity.rejected, (state, action) => {
      return {
        ...state,
        activityStatus: LoadingStatus.failed,
      };
    });
  },
});

export default activitySlice.reducer;
