import axios from "../../../utils/axios";
import { AxiosPromise } from "axios";
import { AccreditationRole, AccreditationRoleVisibility } from "../models/role";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

type GetAccreditationRoleResponse = {
  data: AccreditationRole;
  meta: ApiResponseMeta;
};

export type CreateAccreditationRoleRequest = {
  orgId: string;
  visibility: AccreditationRoleVisibility;
  name?: string;
  description?: string;
  accreditationIds?: number[];
};

type CreateAccreditationRoleResponse = {
  data: AccreditationRole;
  meta: ApiResponseMeta;
};

export type QueryAccreditationRoleRequest = {
  orgId: string;
  visibility?: AccreditationRoleVisibility;
  name?: string;
  description?: string;
  accreditationIds?: number[];
};

type QueryAccreditationRoleResponse = {
  data: AccreditationRole[];
  meta: ApiResponseMeta;
};

export type UpdateAccreditationRoleRequest = {
  id: string;
  orgId: string;
  visibility?: AccreditationRoleVisibility;
  name?: string;
  description?: string;
  accreditationIds?: number[];
};

type UpdateAccreditationRoleResponse = {
  meta: ApiResponseMeta;
};

async function createAccreditationRoleAsync(
  body: CreateAccreditationRoleRequest
): Promise<AxiosPromise<CreateAccreditationRoleResponse>> {
  return await axios.post("/accreditations/roles/v1", body);
}

async function queryAccreditationRolesAsync(
  body: QueryAccreditationRoleRequest
): Promise<AxiosPromise<QueryAccreditationRoleResponse>> {
  return await axios.post("/accreditations/roles/v1/query", body);
}

async function getAccreditationRoleAsync(
  id: string
): Promise<AxiosPromise<GetAccreditationRoleResponse>> {
  return await axios.get(`/accreditations/roles/v1/${id}`);
}

async function updateAccreditationRoleAsync(
  body: UpdateAccreditationRoleRequest
): Promise<AxiosPromise<UpdateAccreditationRoleResponse>> {
  return await axios.put(`/accreditations/roles/v1/${body.id}`, body);
}

export {
  createAccreditationRoleAsync,
  queryAccreditationRolesAsync,
  getAccreditationRoleAsync,
  updateAccreditationRoleAsync,
};
