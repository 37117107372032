import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import {
  selectIncludeExpiredChallengesInScores,
  setIncludeExpiredChallengesInScores,
} from "../../features/user/slices/userSlice";
import useAppDispatch from "../../hooks/useAppDispatch";
import { withTheme } from "@emotion/react";
import { ThemeWithVisiblyExtensions } from "../../theme";
import useAppSelector from "../../hooks/useAppSelector";

type IncludeExpiredChallengesInScoresToggleProps = {
  theme: ThemeWithVisiblyExtensions;
};

function IncludeExpiredChallengesInScoresToggle({
  theme,
}: IncludeExpiredChallengesInScoresToggleProps) {
  const appDispatch = useAppDispatch();
  const include = useAppSelector(selectIncludeExpiredChallengesInScores);

  const toggleHandler = (event: React.SyntheticEvent, checked: boolean) => {
    appDispatch(setIncludeExpiredChallengesInScores(checked));
  };

  return (
    <Grid container spacing={2}>
      <Grid item margin={1}>
        <FormGroup>
          <FormControlLabel
            checked={include}
            onChange={toggleHandler}
            control={
              <Switch
                data-cy="includeMissedChallengesWithinScoresToggle"
                sx={{
                  "& .MuiSwitch-switchBase": {
                    color: theme.palette.primary.main,
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#DADADA",
                  },
                }}
              />
            }
            label={
              <Typography variant="body2">
                Include missed challenges within scores
              </Typography>
            }
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default withTheme(IncludeExpiredChallengesInScoresToggle);
