import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Card as MuiCard,
  Typography,
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  Select as MuiSelect,
  MenuItem,
  Button as MuiButton,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Divider as MuiDivider,
  Box,
  CardContent,
  Grid,
} from "@mui/material";
import { SpacingProps, spacing } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { useSnackbar } from "notistack";
import { Wrapper } from "../../../components/layout/Common";
import { useCurrentUser } from "../../user/hooks/userHooks";
import { getIndustries } from "../../organisation/slices/industriesSlice";
import { createOrganisation } from "../../organisation/services/organisationsService";
import { getUser } from "../../user/slices/userSlice";
import { OrganisationCapabilities } from "../../organisation/models/organisation";
import { CheckCircle } from "react-feather";
import Loader from "../../../components/Loader";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;
const Button = styled(MuiButton)<ButtonProps>(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Select = styled(MuiSelect)<{ my?: number }>(spacing);

function InternalCreateOrganisation() {
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [orgIdCreated, setOrgIdCreated] = useState<string | null>(null);

  const industries = useAppSelector((state) => state.industries);

  const getCapabilities = (
    checkIns: boolean,
    skills: boolean,
    learning: boolean,
    accreditations: boolean
  ) => {
    var result = [];
    if (checkIns) {
      result.push(OrganisationCapabilities.CheckIns);
    }

    if (skills) {
      result.push(OrganisationCapabilities.Skills);
    }

    if (learning) {
      result.push(OrganisationCapabilities.Learning);
    }

    if (accreditations) {
      result.push(OrganisationCapabilities.Accreditations);
    }

    return result;
  };

  useEffect(() => {
    dispatch(getIndustries());
  }, [dispatch]);

  return (
    <>
      <Helmet title="Create Organisation" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        data-cy="createOrganisationHeader"
      >
        Create Organisation
      </Typography>
      <Divider my={6} />
      {orgIdCreated && (
        <Card>
          <CardContent>
            <Typography variant="h5">Organisation has been created.</Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="40vh"
              direction="column"
              spacing={4}
            >
              <Grid item>
                <CheckCircle color="green" size="100" />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `/organisation/choose?allowAutoOrgSelect=1&targetOrgId=${orgIdCreated}`
                    )
                  }
                >
                  Switch to Organisation
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {!orgIdCreated && (
        <Box display="flex" justifyContent="center">
          <Wrapper style={{ maxWidth: "500px" }}>
            <Card>
              <Formik
                initialValues={{
                  isDemoOrg: "true",
                  organisationName: "",
                  organisationWebsite: "",
                  industry: "",
                  numberOfStaffInOrganisation: "",
                  canUseChallengeLibrary: false,
                  canMakeTemplatePublic: false,
                  canUseHierarchicalReviews: false,
                  canUseDeviceMediaForChallenges: false,
                  checkInsAllowMotionTracking: false,
                  canViewChallengeSetPerformance: false,
                  canMakePublicAccreditationSpecs: false,
                  checkIns: false,
                  skills: false,
                  learning: false,
                  accreditations: false,
                  submit: false,
                }}
                validationSchema={Yup.object().shape({
                  isDemoOrg: Yup.string().required(),
                  organisationName: Yup.string()
                    .max(255)
                    .required("Organisation name is required"),
                  organisationWebsite: Yup.string()
                    .max(255)
                    .matches(
                      new RegExp(
                        "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
                      ),
                      "Organisation website must be a valid url"
                    )
                    .optional(),
                  industry: Yup.string()
                    .max(255)
                    .required("Industry is required"),
                  numberOfStaffInOrganisation: Yup.string()
                    .max(255)
                    .required(
                      "Number of staff in the organisation is required"
                    ),
                  canUseChallengeLibrary: Yup.bool(),
                  canMakeTemplatePublic: Yup.bool(),
                  canUseHierarchicalReviews: Yup.bool(),
                  canUseDeviceMediaForChallenges: Yup.bool(),
                  checkInsAllowMotionTracking: Yup.bool(),
                  canViewChallengeSetPerformance: Yup.bool(),
                  canMakePublicAccreditationSpecs: Yup.bool(),
                  checkIns: Yup.bool(),
                  skills: Yup.bool(),
                })}
                onSubmit={async (values, { setStatus, setSubmitting }) => {
                  try {
                    if (!user) {
                      return;
                    }

                    setSubmitting(true);
                    var result = await createOrganisation({
                      name: values.organisationName,
                      website: values.organisationWebsite,
                      industry: values.industry,
                      staffCount: parseInt(values.numberOfStaffInOrganisation),
                      email: user.email ?? "",
                      country: "UK",
                      creatorEmailInOrg: user.email ?? "",
                      featureFlags: {
                        canUseChallengeLibrary: values.canUseChallengeLibrary,
                        canMakeTemplatePublic: values.canMakeTemplatePublic,
                        canUseDeviceMediaForChallenges:
                          values.canUseDeviceMediaForChallenges,
                        canUseHierarchicalReviews:
                          values.canUseHierarchicalReviews,
                        canViewChallengeSetPerformance:
                          values.canViewChallengeSetPerformance,
                        checkInsAllowMotionTracking:
                          values.checkInsAllowMotionTracking,
                        canMakePublicAccreditationSpecs:
                          values.canMakePublicAccreditationSpecs,
                      },
                      capabilities: getCapabilities(
                        values.checkIns,
                        values.skills,
                        values.learning,
                        values.accreditations
                      ),
                      isDemoOrg: values.isDemoOrg === "true",
                    });

                    if (result.data.data.id) {
                      await dispatch(getUser(user?.id ?? "")).then((result) => {
                        localStorage.removeItem("user");
                        localStorage.setItem("user", JSON.stringify(result));
                      });

                      setOrgIdCreated(result.data.data.id);
                    }
                    setSubmitting(false);
                  } catch (error: any) {
                    const message =
                      error.response.data?.meta?.errorMessage ||
                      "Something went wrong";

                    setStatus({ success: false });
                    setSubmitting(false);
                    const snackbarKey = enqueueSnackbar(message, {
                      variant: "error",
                      onClick: () => closeSnackbar(snackbarKey),
                    });
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl fullWidth>
                      <Typography
                        component="h1"
                        variant="subtitle2"
                        pl={4}
                        mt={2}
                        fontWeight="bold"
                      >
                        Is this organisation for demo purposes?
                      </Typography>
                      <Select
                        labelId="isDemoOrg-select-label"
                        name="isDemoOrg"
                        value={values.isDemoOrg}
                        defaultValue={"true"}
                        onChange={handleChange}
                        error={Boolean(touched.isDemoOrg && errors.isDemoOrg)}
                        fullWidth
                        onBlur={handleBlur}
                        label="isDemoOrg"
                        my={2}
                      >
                        <MenuItem value={"true"}>Yes</MenuItem>
                        <MenuItem value={"false"}>No</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      component="h1"
                      variant="subtitle2"
                      pl={4}
                      mt={2}
                      fontWeight="bold"
                    >
                      Organisation name
                    </Typography>
                    <TextField
                      type="organisationName"
                      name="organisationName"
                      value={values.organisationName}
                      error={Boolean(
                        touched.organisationName && errors.organisationName
                      )}
                      fullWidth
                      helperText={
                        touched.organisationName && errors.organisationName
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                    <Typography
                      component="h1"
                      variant="subtitle2"
                      pl={4}
                      mt={2}
                      fontWeight="bold"
                    >
                      Organisation website
                    </Typography>
                    <TextField
                      type="organisationWebsite"
                      name="organisationWebsite"
                      value={values.organisationWebsite}
                      error={Boolean(
                        touched.organisationWebsite &&
                          errors.organisationWebsite
                      )}
                      fullWidth
                      helperText={
                        touched.organisationWebsite &&
                        errors.organisationWebsite
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                    <FormControl fullWidth>
                      <Typography
                        component="h1"
                        variant="subtitle2"
                        pl={4}
                        mt={2}
                        fontWeight="bold"
                      >
                        Industry
                      </Typography>
                      <Select
                        labelId="industry-select-label"
                        name="industry"
                        value={values.industry}
                        onChange={handleChange}
                        error={Boolean(touched.industry && errors.industry)}
                        fullWidth
                        onBlur={handleBlur}
                        label="Industry"
                        my={2}
                      >
                        {industries.map((element) => {
                          return (
                            <MenuItem value={element.name}>
                              {element.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography
                        component="h1"
                        variant="subtitle2"
                        pl={4}
                        mt={2}
                        fontWeight="bold"
                      >
                        Number of staff in the organisation
                      </Typography>
                      <Select
                        labelId="numberOfStaffInOrganisation-select-label"
                        name="numberOfStaffInOrganisation"
                        value={values.numberOfStaffInOrganisation}
                        onChange={handleChange}
                        error={Boolean(
                          touched.numberOfStaffInOrganisation &&
                            errors.numberOfStaffInOrganisation
                        )}
                        fullWidth
                        onBlur={handleBlur}
                        label="numberOfStaffInOrganisation"
                        my={2}
                      >
                        <MenuItem value={10}>1-10 staff</MenuItem>
                        <MenuItem value={50}>11-50 staff</MenuItem>
                        <MenuItem value={100}>51-100 staff</MenuItem>
                        <MenuItem value={150}>100+ staff</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl mt={2} fullWidth>
                      <Typography
                        component="h1"
                        variant="subtitle2"
                        pl={4}
                        fontWeight="bold"
                      >
                        Feature flags
                      </Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.canUseChallengeLibrary}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="canUseChallengeLibrary"
                            />
                          }
                          label="Use Challenge Library"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.canMakeTemplatePublic}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="canMakeTemplatePublic"
                            />
                          }
                          label="Publish Challenge Sets to Library"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.canUseHierarchicalReviews}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="canUseHierarchicalReviews"
                            />
                          }
                          label="Hierarchical Challenge reviews"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.canUseDeviceMediaForChallenges}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="canUseDeviceMediaForChallenges"
                            />
                          }
                          label="Allow upload from gallery when completing Challenges"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.checkInsAllowMotionTracking}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="checkInsAllowMotionTracking"
                            />
                          }
                          label="Track users' motion when using Check Ins"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.canViewChallengeSetPerformance}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="canViewChallengeSetPerformance"
                            />
                          }
                          label="View Challenge Set Performance"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.canMakePublicAccreditationSpecs}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="canMakePublicAccreditationSpecs"
                            />
                          }
                          label="Can make Public Accreditation Specs"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl mt={2} fullWidth>
                      <Typography
                        component="h1"
                        variant="subtitle2"
                        pl={4}
                        fontWeight="bold"
                      >
                        Capabilities
                      </Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.checkIns}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="checkIns"
                            />
                          }
                          label="Check Ins"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.skills}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="skills"
                            />
                          }
                          label="Challenges"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.learning}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="learning"
                            />
                          }
                          label="Learning"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.accreditations}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="accreditations"
                            />
                          }
                          label="Accreditations"
                        />
                      </FormGroup>
                    </FormControl>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      mt={4}
                      p={4}
                    >
                      Create organisation
                    </Button>
                    {isSubmitting && (
                      <Grid
                        container
                        mt={8}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <Loader />
                        </Grid>
                      </Grid>
                    )}
                  </form>
                )}
              </Formik>
            </Card>
          </Wrapper>
        </Box>
      )}
    </>
  );
}

export default InternalCreateOrganisation;
