import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { HealthCheck } from "../models/healthCheck";
import * as organisationService from "../services/organisationService";

export const getHealthCheck = createAsyncThunk(
  "organisation/healthCheck",
  async (orgId: string, { rejectWithValue }) => {
    try {
      return (await organisationService.getHealthCheck(orgId)).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface OrganisationState {
  healthCheck?: HealthCheck;
  status: LoadingStatus;
  error: string;
}

const initialState: OrganisationState = {
  status: LoadingStatus.idle,
  error: "",
};

const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHealthCheck.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });

    builder.addCase(getHealthCheck.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        healthCheck: action.payload.data,
      };
    });

    builder.addCase(getHealthCheck.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
  },
});

export default organisationSlice.reducer;
