import React from "react";

import useAuth from "../../hooks/useAuth";
import NavigateToUserHome from "../NavigateToUserHome";

type VisiblyLearningDesignerGuardProps = {
  children: React.ReactNode;
};

function VisiblyLearningDesignerGuard({
  children,
}: VisiblyLearningDesignerGuardProps) {
  const { isVisiblySupport, isVisiblyAdmin, isVisiblyLearningDesigner } =
    useAuth();

  if (!isVisiblySupport && !isVisiblyAdmin && !isVisiblyLearningDesigner) {
    return <NavigateToUserHome />;
  }

  return <>{children}</>;
}

export default VisiblyLearningDesignerGuard;
