import { Box, Button, Grid, Typography } from "@mui/material";

function ErrorPage() {
  return (
    <>
      <Box height="100vh">
        <Grid
          style={{ position: "relative", top: "30%" }}
          rowSpacing={3}
          container
          alignContent="center"
          alignItems="center"
          alignSelf="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight="bold">
              Whoops, something went wrong
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Please either refresh the page or return home to try again.
            </Typography>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#00d79c" }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Go home
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ErrorPage;
