import { ReactNode, useEffect, useState, useCallback } from "react";
import { useCurrentUser } from "../features/user/hooks/userHooks";

import { intercomConfig } from "../config";
import { IntercomProvider } from "react-use-intercom";
import { useIntercom } from "react-use-intercom";

interface ThirdPartyServicesProviderInnerProps {
  children: ReactNode;
}

interface bootState {
  isBooted: boolean;
  userIdBootedFor: string | null;
}

const ThirdPartyServicesProviderInner = ({
  children,
}: ThirdPartyServicesProviderInnerProps) => {
  const [bootState, setBootState] = useState({
    isBooted: false,
    userIdBootedFor: null,
  } as bootState);
  const currentUser = useCurrentUser();
  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();

  const manageIntercom = useCallback(() => {
    if (bootState.isBooted) {
      shutdownIntercom();
    }
    if (currentUser) {
      bootIntercom({
        userId: currentUser.id,
        name: `${currentUser.forenames} ${currentUser.lastName}`,
        email: currentUser.email,
        companies: currentUser.orgs?.map((org) => {
          return {
            companyId: org.id,
          };
        }),
      });
    } else {
      bootIntercom();
    }
  }, [currentUser, bootState, bootIntercom, shutdownIntercom]);

  useEffect(() => {
    (async () => {
      const currentUserId = currentUser?.id ?? null;
      if (bootState.isBooted && currentUserId === bootState.userIdBootedFor) {
        return;
      }

      manageIntercom();

      setBootState({
        isBooted: true,
        userIdBootedFor: currentUserId,
      });
    })();
  }, [currentUser, bootState, manageIntercom]);

  return <>{children}</>;
};

interface ThirdPartyServicesProviderProps {
  children: ReactNode;
}

const ThirdPartyServicesProvider = ({
  children,
}: ThirdPartyServicesProviderProps) => {
  return (
    <IntercomProvider appId={intercomConfig.appId!}>
      <ThirdPartyServicesProviderInner>
        {children}
      </ThirdPartyServicesProviderInner>
    </IntercomProvider>
  );
};

export default ThirdPartyServicesProvider;
