import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { TaskSpecChallengeActivitiesRequest } from "../models/taskStats";

export async function taskSpecChallengeActivitiesQuery(
  request: TaskSpecChallengeActivitiesRequest
): Promise<AxiosPromise> {
  const body = {
    orgId: request.orgId,
    userId: request.userId,
    timeRange: request.timeRange,
    taskSpecId: request.taskSpecId,
  };
  return await axios.post(
    `stats/tasks/v1/challenger-activity-by-task-spec`,
    body
  );
}
