import { lazy } from "react";
import GuestGuard from "../components/guards/GuestGuard";
import NavigateToUserHome from "../components/NavigateToUserHome";
import BasicLayout from "../layouts/Basic";
import SignIn from "../features/account/pages/signIn";
import ForgotPassword from "../features/account/pages/resetPassword/ForgotPassword";
import { RouteObject } from "react-router-dom";

const Register = lazy(() => import("../features/account/pages/register"));

const RegisterSuccess = lazy(
  () => import("../features/user/pages/RegisterSuccess")
);
const AcceptInvite = lazy(() => import("../features/user/pages/AcceptInvite"));

const DeleteAccount = lazy(
  () => import("../features/account/pages/deleteAccount")
);

const RegisterConfirm = lazy(
  () => import("../features/account/pages/register/RegisterConfirm")
);

const ResetPassword = lazy(
  () => import("../features/account/pages/resetPassword")
);

const CheckEmail = lazy(
  () => import("../features/account/pages/resetPassword/CheckEmail")
);

export const unauthenticatedRoutes = [
  {
    path: "/",
    element: <NavigateToUserHome />,
  },
  {
    children: [
      {
        element: <BasicLayout />,
        children: [
          {
            path: "delete-account",
            element: <DeleteAccount />,
          },
          {
            path: "register-success",
            element: <RegisterSuccess />,
          },
          {
            path: "accept-invite/:id",
            element: <AcceptInvite />,
          },
        ],
      },
      {
        element: (
          <GuestGuard>
            <BasicLayout />
          </GuestGuard>
        ),
        children: [
          {
            path: "auth",
            children: [
              {
                path: "sign-in",
                element: <SignIn />,
              },
              {
                path: "forgot-password",
                element: <ForgotPassword />,
              },
              {
                path: "check-email",
                element: <CheckEmail />,
              },
            ],
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
        ],
      },
      {
        element: <BasicLayout />,
        children: [
          {
            path: "register/:id",
            element: <Register />,
          },
          {
            path: "register-confirm",
            element: <RegisterConfirm />,
          },
        ],
      },
    ],
  },
] as RouteObject[];
