import React, { ReactElement } from "react";
import { Worker } from "./models/worker";
import { Group } from "./models/group";

type PageHeaderContextProps = {
  group?: Group;
  setGroup?: React.Dispatch<React.SetStateAction<Group | undefined>>;
  worker?: Worker;
  setWorker?: React.Dispatch<React.SetStateAction<Worker | undefined>>;
  button?: ReactElement;
  setButton?: React.Dispatch<React.SetStateAction<ReactElement | undefined>>;
};

export const PageHeaderContext = React.createContext<PageHeaderContextProps>(
  {}
);
