import { SidebarItemsType } from "../../types/sidebar";
import { Briefcase, User } from "react-feather";

export const internalAdminSection = [
  {
    href: "/internal-admin/create-organisation",
    icon: Briefcase,
    title: () => "Create Organisation",
  },
  {
    href: "/internal-admin/invite-user",
    icon: User,
    title: () => "Invite admin user",
  },
] as SidebarItemsType[];
