import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

type BackButtonProps = {
  to: string;
};

function BackButton(props: BackButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      style={{ marginRight: 10, float: "left" }}
      onClick={() => {
        navigate(props.to);
      }}
    >
      <ChevronLeftIcon />
    </Button>
  );
}

export default BackButton;
