import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  ChallengeDetails,
  GetChallengeDetailsRequest,
} from "../models/challengeDetails";
import { getChallengeDetailsAsync } from "../services/challengeDetailsService";

export const getChallengeDetails = createAsyncThunk(
  "challenge-details/getChallengeDetails",
  async (request: GetChallengeDetailsRequest, { rejectWithValue }) => {
    try {
      const getChallengeDetailsResponse = await getChallengeDetailsAsync(
        request.challengeId
      );

      return getChallengeDetailsResponse.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface ChallengeDetailState {
  challengeDetails?: ChallengeDetails;
  status: LoadingStatus;
  error: string;
}

const initialState: ChallengeDetailState = {
  status: LoadingStatus.idle,
  error: "",
};

const challengesDetailsSlice = createSlice({
  name: "challengeDetails",
  initialState,
  reducers: {
    clearChallengeDetails(state) {
      return {
        ...state,
        challengeDetails: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChallengeDetails.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getChallengeDetails.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challengeDetails: { ...action.payload.data },
      };
    });

    builder.addCase(getChallengeDetails.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get challenge details.",
      };
    });
  },
});

export default challengesDetailsSlice.reducer;
