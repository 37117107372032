import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import {
  BulkImportInviteData,
  InviteDataWithValidation,
} from "../models/invite";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

type ValidateBulkImportCsvRequest = {
  orgId: string;
  importId: string;
};

type BulkImportRequest = {
  invites: BulkImportInviteData[];
  orgId: string;
};

type ValidateBulkImportCsvResponse = {
  data: InviteDataWithValidation[];
  meta: ApiResponseMeta;
};

export async function bulkImport(
  request: BulkImportRequest
): Promise<AxiosPromise> {
  const body = {
    invites: request.invites,
    orgId: request.orgId,
  };

  return await axios.post(`invites/v1/bulk-import`, body);
}

export async function validateBulkImportCsv(
  request: ValidateBulkImportCsvRequest
): Promise<AxiosPromise<ValidateBulkImportCsvResponse>> {
  const body = {
    importId: request.importId,
    orgId: request.orgId,
  };

  return await axios.post(`invites/v1/validate-bulk-import-csv`, body);
}
