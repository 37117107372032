import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import { CourseSpec } from "../models/courseSpec";
import {
  BulkEnrolRequest,
  QueryCourseSpecsRequest,
} from "../slices/learningCoursesSlice";
import { BulkEnrol } from "../models/enrolment";

type QueryCourseSpecsResponse = {
  data: CourseSpec[];
  meta: ApiResponseMeta;
};

async function queryCourseSpecsAsync(
  request: QueryCourseSpecsRequest
): Promise<AxiosPromise<QueryCourseSpecsResponse>> {
  return await axios.post(`/learning/v2/course-specs/query`, request);
}

type BulkEnrolResponse = {
  data: BulkEnrol;
  meta: ApiResponseMeta;
};

async function bulkEnrolAsync(
  courseSpecId: string,
  request: BulkEnrolRequest
): Promise<AxiosPromise<BulkEnrolResponse>> {
  return await axios.post(
    `/learning/v2/course-specs/${courseSpecId}/bulk-enrol`,
    request
  );
}

export { queryCourseSpecsAsync, bulkEnrolAsync };
