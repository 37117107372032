import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import {
  AccreditationCompliance,
  RoleCompliance,
  AccreditationComplianceDetail,
} from "../models/compliance";

export type QueryAccreditationCompliancesRequest = {
  orgId: string;
  teamIds?: string[];
  accreditationRoleIds: string[];
};

type QueryAccreditationCompliancesResponse = {
  data: AccreditationCompliance[];
  meta: ApiResponseMeta;
};

export type QueryRoleCompliancesRequest = {
  orgId: string;
  teamIds?: string[];
  accreditationRoleIds?: string[];
};

type QueryRoleCompliancesResponse = {
  data: RoleCompliance[];
  meta: ApiResponseMeta;
};

export type QueryAccreditationComplianceDetailsRequest = {
  orgId: string;
  userId: string;
  accreditationRoleId: string;
};

type QueryAccreditationComplianceDetailsResponse = {
  data: AccreditationComplianceDetail[];
  meta: ApiResponseMeta;
};

async function queryAccreditationCompliancesAsync(
  body: QueryAccreditationCompliancesRequest
): Promise<AxiosPromise<QueryAccreditationCompliancesResponse>> {
  return await axios.post(
    "/accreditations/roles/v1/accreditation-compliance",
    body
  );
}

async function queryRoleCompliancesAsync(
  body: QueryRoleCompliancesRequest
): Promise<AxiosPromise<QueryRoleCompliancesResponse>> {
  return await axios.post("/accreditations/roles/v1/role-compliance", body);
}

async function queryAccreditationComplianceDetailsAsync(
  body: QueryAccreditationComplianceDetailsRequest
): Promise<AxiosPromise<QueryAccreditationComplianceDetailsResponse>> {
  return await axios.post(
    "/accreditations/roles/v1/accreditation-compliance-details",
    body
  );
}

export {
  queryAccreditationCompliancesAsync,
  queryRoleCompliancesAsync,
  queryAccreditationComplianceDetailsAsync,
};
