import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import { LoadingStatus } from "../../../../../../models/loadingStatus";
import { AssignedRole, UnassignDto } from "../../../models/assignedRoles";
import Modal from "../../../../../../components/Modal";

type UnassignMultipleModalProps = {
  assignedRoles: AssignedRole[];
  onClose: () => void;
  show: boolean;
  onConfirm: (rolesToUnassign: UnassignDto[]) => Promise<void>;
};

export default function UnassignMultipleModal(
  props: UnassignMultipleModalProps
) {
  const [unassignGroups, setUnassignGroups] = useState<UnassignDto[]>([]);
  const unassignedRolesStatus = useAppSelector(
    (state) => state.groupRoles.unassignStatus
  );

  useEffect(() => {
    var groups: { roleId: string; userIds: string[] }[] = [];

    props.assignedRoles.forEach((el) => {
      var group = groups.find((e) => e.roleId === el.id);
      if (group) {
        group.userIds.push(el.userId);
      } else {
        groups.push({
          roleId: el.id,
          userIds: [el.userId],
        });
      }
    });

    setUnassignGroups(groups);
  }, [props]);

  return (
    <Modal
      showModal={props.show}
      headerText={"Unassign workers"}
      onClose={props.onClose}
      buttons={[
        {
          text: "Close",
          onClick: props.onClose,
        },
        {
          text: "Unassign",
          isLoadingButton: true,
          onClick: async () => {
            await props.onConfirm(unassignGroups);
            props.onClose();
          },
          loading: unassignedRolesStatus === LoadingStatus.loading,
        },
      ]}
    >
      <Box padding={10}>
        {unassignGroups.length > 0 && (
          <Typography variant="subtitle1">{`Unassign ${
            unassignGroups
              .map((el) => el.userIds)
              .reduce((tot, next) => tot.concat(next)).length
          } workers from ${unassignGroups.length} roles.`}</Typography>
        )}
      </Box>
    </Modal>
  );
}
