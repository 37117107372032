export const mapRoleComplianceStatus = (status: string) => {
  switch (status) {
    case "accredited":
      return "Accredited";
    case "atRisk":
      return "At risk";
    case "notAccredited":
      return "Not accredited";
    default:
      return "";
  }
};

export const reverseMapRoleComplianceStatus = (status: string) => {
  switch (status) {
    case "Accredited":
      return "accredited";
    case "At risk":
      return "atRisk";
    case "Not accredited":
      return "notAccredited";
    default:
      return "";
  }
};
