import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { ReviewDiscrepancy } from "../models/reviewDiscrepancy";
import { getReviewDiscrepancies as getReviewDiscrepanciesService } from "../services/challengeStatsService";

type GetReviewDiscrepanciesRequest = {
  orgId: string;
  dayInterval: number;
  teamId?: string;
};

export const getReviewDiscrepancies = createAsyncThunk(
  "stats-challenges/getReviewDiscrepancies",
  async (request: GetReviewDiscrepanciesRequest, { rejectWithValue }) => {
    try {
      const result = await getReviewDiscrepanciesService(
        request.orgId,
        request.dayInterval,
        request.teamId
      );

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface ReviewDiscrepanciesState {
  reviewDiscrepancies: ReviewDiscrepancy[];
  status: LoadingStatus;
  error: string;
}

const initialState: ReviewDiscrepanciesState = {
  reviewDiscrepancies: [],
  status: LoadingStatus.idle,
  error: "",
};

const reviewDiscrepancySlice = createSlice({
  name: "reviewDiscrepancies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviewDiscrepancies.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getReviewDiscrepancies.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        reviewDiscrepancies: action.payload.data,
      };
    });

    builder.addCase(getReviewDiscrepancies.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get review discrepancies",
      };
    });
  },
});

export default reviewDiscrepancySlice.reducer;
