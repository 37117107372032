import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TaskSpecSummary } from "../models/taskSpecSummary";
import { Rate, ReviewRating } from "../models/dailyRates";
import * as challengeStatsService from "../services/challengeStatsService";
import { getChallengerActivityQuery } from "../services/challengeStatsService";
import { ChallengerActivityResponseModel } from "../models/activity";
import { getReviewerActivityQuery } from "../services/challengeStatsService";
import { ReviewerActivity } from "../models/activity";
import { LoadingStatus } from "../../../models/loadingStatus";
import { TaskSpecUserPerformance } from "../models/taskSpecUserPerformance";

type GetTaskSpecActivityProps = {
  orgId: string;
  dayInterval: number;
  userId?: string;
};

export const getTaskSpecActivity = createAsyncThunk(
  "stats-challengeInstances/getTaskSpecActivitySummary",
  async (request: GetTaskSpecActivityProps, { rejectWithValue }) => {
    try {
      var result = await challengeStatsService.getTaskSpecActivity(
        request.orgId,
        request.dayInterval,
        request.userId
      );

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

type DailyRatesRequest = {
  orgId: string;
  dayInterval: number;
};

type ReviewerActivityRequest = {
  orgId: string;
  dayInterval: number;
  teamId?: string;
};

type GetChallengerActivityRequest = {
  orgId: string;
  dayInterval: number;
  teamId?: string;
};

export const getDailyChallengeCompletion = createAsyncThunk(
  "stats-challengeInstances/dailyChallengeCompletion",
  async (request: DailyRatesRequest, { rejectWithValue }) => {
    try {
      return (
        await challengeStatsService.getDailyChallengeCompletionRates(
          request.orgId,
          request.dayInterval
        )
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getDailyReviewCompletion = createAsyncThunk(
  "stats-challengeInstances/dailyReviewCompletion",
  async (request: DailyRatesRequest, { rejectWithValue }) => {
    try {
      return (
        await challengeStatsService.getDailyReviewCompletionRates(
          request.orgId,
          request.dayInterval
        )
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getDailyReviewRatings = createAsyncThunk(
  "stats-challengeInstances/dailyReviewRatings",
  async (
    request: DailyRatesRequest & { userId?: string },
    { rejectWithValue }
  ) => {
    try {
      return (
        await challengeStatsService.getDailyReviewRatingsQuery(
          request.orgId,
          request.dayInterval,
          request.userId
        )
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export type TaskSpecComponentActivityRequest = {
  orgId: string;
  dayInterval: number;
  taskSpecId: string;
};

export const getTaskSpecComponentActivity = createAsyncThunk(
  "stats-challengeInstances/getTaskSpecComponentActivity",
  async (request: TaskSpecComponentActivityRequest, { rejectWithValue }) => {
    try {
      return (
        await challengeStatsService.getTaskSpecComponentActivity(
          request.orgId,
          request.dayInterval,
          request.taskSpecId
        )
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getChallengerActivity = createAsyncThunk(
  "stats-challengeInstances/getChallengerActivity",
  async (request: GetChallengerActivityRequest, { rejectWithValue }) => {
    try {
      const result = (
        await getChallengerActivityQuery(
          request.orgId,
          request.dayInterval,
          request.teamId
        )
      ).data;

      return result;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getReviewerActivity = createAsyncThunk(
  "stats-challengeInstances/getReviewerActivity",
  async (request: ReviewerActivityRequest, { rejectWithValue }) => {
    try {
      const result = await getReviewerActivityQuery(
        request.orgId,
        request.dayInterval,
        request.teamId
      );

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

type GetTaskSpecUserPerformanceRequest = {
  orgId: string;
  dayInterval: number;
};

export const getTaskSpecUserPerformance = createAsyncThunk(
  "stats-challenges/getTaskSpecUserPerformance",
  async (request: GetTaskSpecUserPerformanceRequest, { rejectWithValue }) => {
    try {
      return (
        await challengeStatsService.getTaskSpecUserPerformance(
          request.orgId,
          request.dayInterval
        )
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface ChallengeStatsState {
  taskSpecSummaries: TaskSpecSummary[];
  taskSpecComponentSummaries: TaskSpecSummary[];
  dailyChallengeCompletion: Rate[];
  dailyReviewCompletion: Rate[];
  dailyReviewRatings: ReviewRating[];
  challengerActivity: ChallengerActivityResponseModel;
  reviewerActivities: ReviewerActivity[];
  taskSpecUserPerformances: TaskSpecUserPerformance[];
  status: LoadingStatus;
  error: string;
}

const initialState: ChallengeStatsState = {
  taskSpecSummaries: [],
  taskSpecComponentSummaries: [],
  dailyChallengeCompletion: [],
  dailyReviewCompletion: [],
  dailyReviewRatings: [],
  reviewerActivities: [],
  challengerActivity: {
    challengerActivities: [],
    submittedChallenges: 0,
    userCount: 0,
  },
  taskSpecUserPerformances: [],
  status: LoadingStatus.idle,
  error: "",
};

const challengeStatsSlice = createSlice({
  name: "challengeStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaskSpecActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpecActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSpecSummaries: action.payload.data,
      };
    });
    builder.addCase(getTaskSpecActivity.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get task spec activities",
      };
    });
    builder.addCase(getDailyChallengeCompletion.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getDailyChallengeCompletion.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        dailyChallengeCompletion: action.payload.data,
      };
    });

    builder.addCase(getDailyChallengeCompletion.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get daily challenge completion rates",
      };
    });
    builder.addCase(getDailyReviewCompletion.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getDailyReviewCompletion.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        dailyReviewCompletion: action.payload.data,
      };
    });

    builder.addCase(getDailyReviewCompletion.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get daily review completion rates",
      };
    });
    builder.addCase(getDailyReviewRatings.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getDailyReviewRatings.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        dailyReviewRatings: action.payload.data,
      };
    });

    builder.addCase(getDailyReviewRatings.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get review ratings",
      };
    });
    builder.addCase(getTaskSpecComponentActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpecComponentActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSpecComponentSummaries: action.payload.data,
      };
    });

    builder.addCase(getTaskSpecComponentActivity.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get task spec component activities",
      };
    });

    builder.addCase(getReviewerActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getReviewerActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        reviewerActivities: [...action.payload.data],
      };
    });

    builder.addCase(getReviewerActivity.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get reviewer activity",
      };
    });

    builder.addCase(getChallengerActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getChallengerActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challengerActivity: { ...action.payload.data },
      };
    });
    builder.addCase(getChallengerActivity.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get challenger activity",
      };
    });
    builder.addCase(getTaskSpecUserPerformance.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpecUserPerformance.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSpecUserPerformances: action.payload.data,
      };
    });

    builder.addCase(getTaskSpecUserPerformance.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get task spec user performance",
      };
    });
  },
});

export default challengeStatsSlice.reducer;
