import React from "react";

import useAuth from "../../hooks/useAuth";
import NavigateToUserHome from "../NavigateToUserHome";

type VisiblyExternalPartnerGuardProps = {
  children: React.ReactNode;
};

function VisiblyExternalPartnerGuard({
  children,
}: VisiblyExternalPartnerGuardProps) {
  const { isVisiblySupport, isVisiblyAdmin, isVisiblyExternalPartner } =
    useAuth();

  if (!isVisiblySupport && !isVisiblyAdmin && !isVisiblyExternalPartner) {
    return <NavigateToUserHome />;
  }

  return <>{children}</>;
}

export default VisiblyExternalPartnerGuard;
