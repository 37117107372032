import styled from "@emotion/styled";

import { Paper } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/authLogo.svg";

export const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  margin-bottom: 32px;
  margin-top: 3vh;
`;

export const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
