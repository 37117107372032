import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  bulkImport,
  validateBulkImportCsv,
} from "../services/invitesCsvService";
import {
  BulkImportInviteData,
  InviteDataWithValidation,
} from "../models/invite";
import { uploadFileToFileStorage } from "../../../services/s3ClientStorageService";

type UploadAndValidateInvitesCsvRequest = {
  orgId: string;
  file: File;
};

type ImportInvitesRequest = {
  orgId: string;
  invites: BulkImportInviteData[];
};

type UploadInvitesCsvRejectPayload = {
  error: string;
};

export const uploadAndValidateInvitesCsv = createAsyncThunk(
  "invitesCsv/uploadAndValidateInvites",
  async (request: UploadAndValidateInvitesCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }

    try {
      var result = await validateBulkImportCsv({
        orgId: request.orgId,
        importId: importId,
      });

      return result.data;
    } catch (err: any) {
      return rejectWithValue({ error: err });
    }
  }
);

export const importInvites = createAsyncThunk<
  null,
  ImportInvitesRequest,
  { rejectValue: UploadInvitesCsvRejectPayload }
>("invitesCsv/importInvites", async (request, { rejectWithValue }) => {
  try {
    await bulkImport({
      orgId: request.orgId,
      invites: request.invites,
    });

    return null;
  } catch (err: any) {
    console.warn(err);
    return rejectWithValue({ error: err });
  }
});

type InvitesCsvFileState = {
  status: LoadingStatus;
  invitesWithValidation: InviteDataWithValidation[];
  error: string | null;
};

const initialState: InvitesCsvFileState = {
  status: LoadingStatus.idle,
  invitesWithValidation: [],
  error: null,
};

const invitesCsvFileSlice = createSlice({
  name: "invitesCsv",
  initialState,
  reducers: {
    clearUploadInvitesCsv: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadAndValidateInvitesCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(uploadAndValidateInvitesCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        invitesWithValidation: action.payload.data,
      };
    });
    builder.addCase(uploadAndValidateInvitesCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        invitesWithValidation: [],
      };
    });

    builder.addCase(importInvites.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importInvites.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        error: null,
      };
    });
    builder.addCase(importInvites.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: action.payload!.error,
      };
    });
  },
});

export const { clearUploadInvitesCsv } = invitesCsvFileSlice.actions;

export default invitesCsvFileSlice.reducer;
