import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { AuditLog } from "../models/auditLog";
import { queryAuditLogsAsync } from "../services/auditLogService";

export const getAuditLogsByActivity = createAsyncThunk(
  "activities/getAuditLogsByActivity",
  async (activityId: string, { rejectWithValue }) => {
    try {
      const result = await queryAuditLogsAsync(activityId);

      return result.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface AuditLogState {
  auditLogs: AuditLog[];
  auditLogsStatus: LoadingStatus;
  error: string;
}

const initialState: AuditLogState = {
  auditLogs: [],
  auditLogsStatus: LoadingStatus.idle,
  error: "",
};

const auditLogSlice = createSlice({
  name: "auditLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAuditLogsByActivity.pending, (state, action) => {
      return { ...state, auditLogsStatus: LoadingStatus.loading };
    });
    builder.addCase(getAuditLogsByActivity.fulfilled, (state, action) => {
      return {
        ...state,
        auditLogsStatus: LoadingStatus.succeeded,
        auditLogs: action.payload.data,
      };
    });
    builder.addCase(getAuditLogsByActivity.rejected, (state, action) => {
      return {
        ...state,
        auditLogsStatus: LoadingStatus.failed,
        auditLogs: [],
      };
    });
  },
});

export default auditLogSlice.reducer;
