export interface CreateOrganisationRequest {
  name: string;
  industry: string;
  website: string;
  staffCount: number;
  email: string;
  country: string;
  creatorEmailInOrg: string;
  featureFlags?: FeatureFlags;
  capabilities?: OrganisationCapabilities[];
  isDemoOrg?: boolean;
}

export interface Organisation {
  id: string;
  name: string;
  industry: string;
  website: string;
  createdDate: string;
  country: string;
  email: string;
  verified: boolean;
  emailVerified: boolean;
  featureFlags?: FeatureFlags;
  capabilities?: OrganisationCapabilities[];
}

interface OrganisationStats {
  staffCount: number;
  memberCount: number;
  teamCount: number;
  challengeCount: number;
}

export interface OrganisationDetail extends Organisation {
  stats: OrganisationStats;
}

export interface FeatureFlags {
  canUseChallengeLibrary: boolean;
  canMakeTemplatePublic: boolean;
  canUseHierarchicalReviews: boolean;
  canUseDeviceMediaForChallenges: boolean;
  canViewChallengeSetPerformance: boolean;
  checkInsAllowMotionTracking: boolean;
  canMakePublicAccreditationSpecs: boolean;
}

export enum OrganisationCapabilities {
  Skills = "skills",
  CheckIns = "checkIns",
  Learning = "learning",
  Accreditations = "accreditations",
}
