import { RouteObject } from "react-router-dom";
import VisiblyExternalPartnerGuard from "../components/guards/VisiblyExternalGuard";
import VisiblyLearningDesignerGuard from "../components/guards/VisiblyLearningDesignerGuard";
import BasicLayout from "../layouts/Basic";
import { lazy } from "react";

const VideoUpload = lazy(
  () => import("../features/external/pages/videoUpload")
);

const Transcribe = lazy(() => import("../features/external/pages/transcribe"));

export const externalRoutes = [
  {
    path: "external",
    element: <BasicLayout />,
    children: [
      {
        path: "video-upload",
        element: (
          <VisiblyExternalPartnerGuard>
            <VideoUpload />
          </VisiblyExternalPartnerGuard>
        ),
      },
      {
        path: "transcribe",
        element: (
          <VisiblyLearningDesignerGuard>
            <Transcribe />
          </VisiblyLearningDesignerGuard>
        ),
      },
    ],
  },
] as RouteObject[];
