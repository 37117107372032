import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const {
    isAuthenticated,
    isInitialized,
    isVisiblyExternalPartner,
    isVisiblyAdmin,
    isVisiblySupport,
  } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  if (isVisiblyExternalPartner && !isVisiblyAdmin && !isVisiblySupport) {
    return <Navigate to="/external/video-upload" />;
  }

  return <>{children}</>;
}

export default AuthGuard;
