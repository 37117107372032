import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { ChallengeWithReviewDiscrepancy } from "../models/reviewDiscrepancy";
import { getChallengesWithReviewDiscrepancies as getChallengesWithReviewDiscrepanciesService } from "../services/challengeStatsService";

type GetChallengesWithReviewDiscrepanciesRequest = {
  orgId: string;
  date?: Date;
  teamId?: string;
};

export const getChallengesWithReviewDiscrepancies = createAsyncThunk(
  "stats-challenges/getChallengesWithReviewDiscrepancies",
  async (
    request: GetChallengesWithReviewDiscrepanciesRequest,
    { rejectWithValue }
  ) => {
    if (request.date) {
      try {
        const result = await getChallengesWithReviewDiscrepanciesService(
          request.orgId,
          request.date,
          request.teamId
        );

        return result.data;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }

    return {
      data: [],
      meta: {},
    };
  }
);

interface ChallengeWithReviewDiscrepanciesState {
  challengesWithReviewDiscrepancies: ChallengeWithReviewDiscrepancy[];
  status: LoadingStatus;
  error: string;
}

const initialState: ChallengeWithReviewDiscrepanciesState = {
  challengesWithReviewDiscrepancies: [],
  status: LoadingStatus.idle,
  error: "",
};

const challengeWithReviewDiscrepancySlice = createSlice({
  name: "challengeWithReviewDiscrepancy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getChallengesWithReviewDiscrepancies.pending,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.loading,
          showChallengesWithReviewDiscrepancies: false,
        };
      }
    );
    builder.addCase(
      getChallengesWithReviewDiscrepancies.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          challengesWithReviewDiscrepancies: action.payload.data,
        };
      }
    );
    builder.addCase(
      getChallengesWithReviewDiscrepancies.rejected,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.failed,
          challengesWithReviewDiscrepancies: [],
          error: "Failed to get challenges with review discrepancies",
        };
      }
    );
  },
});

export default challengeWithReviewDiscrepancySlice.reducer;
