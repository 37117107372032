import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { OrganisationOverview } from "../models/organisationOverview";

type OrganisationOverviewResponse = {
  data: OrganisationOverview;
  meta: ApiResponseMeta;
};

async function getOrganisationOverviewQuery(
  orgId: string
): Promise<AxiosPromise<OrganisationOverviewResponse>> {
  const body = {
    orgId: orgId,
  };

  return await axios.post(`stats/orgs/v1/overview`, body);
}

export { getOrganisationOverviewQuery as getOrganisationOverview };
