import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import {
  CreateOrganisationRequest,
  OrganisationDetail,
} from "../models/organisation";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

export type CreateOrganisationResponse = {
  data: OrganisationDetail;
  meta: ApiResponseMeta;
};

async function createOrganisation(
  request: CreateOrganisationRequest
): Promise<AxiosPromise<CreateOrganisationResponse>> {
  return await axios.post("orgs/v1", request);
}

export { createOrganisation };
