import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  CreateAccreditationRoleConditionRequest,
  QueryAccreditationRoleConditionRequest,
  UpdateAccreditationRoleConditionRequest,
  createAccreditationRoleConditionAsync,
  getAccreditationRoleConditionAsync,
  queryAccreditationRoleConditionsAsync,
  updateAccreditationRoleConditionAsync,
} from "../services/accreditationRoleConditionsService";
import { AccreditationRoleCondition } from "../models/roleCondition";

export const createAccreditationRoleCondition = createAsyncThunk(
  "accreditation-role-conditions/createAccreditationRoleCondition",
  async (
    request: CreateAccreditationRoleConditionRequest,
    { rejectWithValue }
  ) => {
    try {
      const createAccreditationRoleConditionResponse =
        await createAccreditationRoleConditionAsync(request);

      return createAccreditationRoleConditionResponse.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const updateAccreditationRoleCondition = createAsyncThunk(
  "accreditation-role-conditions/updateAccreditationRoleCondition",
  async (
    request: UpdateAccreditationRoleConditionRequest,
    { rejectWithValue }
  ) => {
    try {
      await updateAccreditationRoleConditionAsync(request);
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const queryAccreditationRoleConditions = createAsyncThunk(
  "accreditation-role-conditions/queryAccreditationRoleConditions",
  async (
    request: QueryAccreditationRoleConditionRequest,
    { rejectWithValue }
  ) => {
    try {
      const queryAccreditationRoleConditionsResponse =
        await queryAccreditationRoleConditionsAsync(request);

      return queryAccreditationRoleConditionsResponse.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

export const getAccreditationRoleCondition = createAsyncThunk(
  "accreditation-role-conditions/getAccreditationRoleCondition",
  async (id: string, { rejectWithValue }) => {
    try {
      const getAccreditationRolesResponse =
        await getAccreditationRoleConditionAsync(id);

      return getAccreditationRolesResponse.data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

interface AccreditationRoleConditionsState {
  accreditationRoleCondition: AccreditationRoleCondition;
  accreditationRoleConditions: AccreditationRoleCondition[];
  status: LoadingStatus;
  error: string;
}

const initialState: AccreditationRoleConditionsState = {
  accreditationRoleCondition: {} as AccreditationRoleCondition,
  accreditationRoleConditions: [],
  status: LoadingStatus.idle,
  error: "",
};

const accreditationRoleConditionsSlice = createSlice({
  name: "accreditationRoleConditions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAccreditationRoleCondition.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(
      createAccreditationRoleCondition.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          accreditationRoleCondition: action.payload.data,
        };
      }
    );
    builder.addCase(createAccreditationRoleCondition.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to create accreditation role condition.",
      };
    });

    builder.addCase(updateAccreditationRoleCondition.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(
      updateAccreditationRoleCondition.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
        };
      }
    );
    builder.addCase(updateAccreditationRoleCondition.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to update accreditation role condition.",
      };
    });

    builder.addCase(getAccreditationRoleCondition.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(
      getAccreditationRoleCondition.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          accreditationRoleCondition: action.payload.data,
        };
      }
    );
    builder.addCase(getAccreditationRoleCondition.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get accreditation role condition.",
      };
    });

    builder.addCase(queryAccreditationRoleConditions.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(
      queryAccreditationRoleConditions.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          accreditationRoleConditions: action.payload.data,
        };
      }
    );
    builder.addCase(queryAccreditationRoleConditions.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to query accreditation role conditions.",
      };
    });
  },
});

export default accreditationRoleConditionsSlice.reducer;
