import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { Invite } from "../models/invite";
import * as inviteService from "../services/invitesService";

export type GetInvitesRequest = {
  orgId: string;
  searchString?: string;
  firstNames?: string;
  lastName?: string;
  handle?: string;
  email?: string;
};

export const getInvites = createAsyncThunk(
  "invites/setInvites",
  async ({ orgId, searchString }: GetInvitesRequest, { rejectWithValue }) => {
    try {
      return (
        await inviteService.getInvites({
          orgId: orgId,
          searchString: searchString,
        })
      ).data;
    } catch (err) {
      return rejectWithValue({ error: err });
    }
  }
);

type InviteState = {
  invites: Invite[];
  status: LoadingStatus;
  error: string;
};

const initialState: InviteState = {
  invites: [],
  status: LoadingStatus.idle,
  error: "",
};

const inviteSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    clearPublicInvite: (state) => {
      return {
        ...state,
        publicInvite: null,
        status: LoadingStatus.idle,
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvites.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getInvites.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        invites: action.payload.data,
      };
    });

    builder.addCase(getInvites.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        invites: [],
      };
    });
  },
});

export const { clearPublicInvite } = inviteSlice.actions;
export default inviteSlice.reducer;
