import { Navigate, RouteObject, useParams } from "react-router-dom";
import VisiblyOSLayout from "../visiblyOS/VisiblyOSLayout";
import { lazy, ReactElement } from "react";
import { useContext, useEffect } from "react";
import useAppDispatch from "../hooks/useAppDispatch";
import { PageHeaderContext } from "../visiblyOS/PageHeaderContext";
import { getGroup } from "../visiblyOS/features/groups/slices/groupsSlice";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";
import WorkerRoles from "../visiblyOS/features/groups/pages/group/workerRoles/WorkerRoles";

const Groups = lazy(
  () => import("../visiblyOS/features/groups/pages/groups/Groups")
);

const GroupRoles = lazy(
  () => import("../visiblyOS/features/groups/pages/group/GroupRoles")
);

export const groupsRoutes = [
  {
    path: "compliance",
    element: (
      <CanViewCapabilityRouteGuard capability="accreditations">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "groups/:groupId",
        children: [
          {
            path: "",
            element: <NavigateToGroupHome />,
          },
          {
            path: "group-roles",
            element: (
              <GroupWrapper>
                <GroupRoles />
              </GroupWrapper>
            ),
          },
          {
            path: "worker-roles",
            element: (
              <GroupWrapper>
                <WorkerRoles />
              </GroupWrapper>
            ),
          },
        ],
      },
    ],
  },
] as RouteObject[];

type GroupWrapperProps = {
  children: ReactElement;
};

function GroupWrapper(props: GroupWrapperProps) {
  const dispatch = useAppDispatch();
  const { groupId } = useParams();
  const { group, setGroup, setButton } = useContext(PageHeaderContext);

  useEffect(() => {
    if (groupId && (!group || groupId !== group.id)) {
      dispatch(getGroup(groupId))
        .unwrap()
        .then((value) => {
          setGroup!(value.data);
        });
    }
    setButton!(undefined);
  }, [group, groupId, setGroup, dispatch, setButton]);

  return <>{group && groupId === group.id ? props.children : null}</>;
}

function NavigateToGroupHome() {
  const { groupId } = useParams();

  return <Navigate to={`/compliance/groups/${groupId}/group-roles`} />;
}
