import { Box } from "@mui/material";

type StatusDotProps = {
  colour: "red" | "amber" | "green" | string;
  marginTop?: number;
  marginBottom?: number;
};

function StatusDot(props: StatusDotProps) {
  return (
    <Box
      style={{
        display: "flex",
        width: "10px",
        height: "10px",
        backgroundColor:
          props.colour === "red"
            ? "#E53B3B"
            : props.colour === "amber"
            ? "#EDAE00"
            : props.colour === "green"
            ? "#1BCF60"
            : props.colour,
        borderRadius: "50%",
        float: "left",
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
        marginRight: 10,
      }}
    />
  );
}

export default StatusDot;
