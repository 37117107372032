import { Amplify } from "aws-amplify";
import { cognitoConfig, s3Config } from "../config";
import {
  GetObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { fetchAuthSession } from "aws-amplify/auth";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";
import { Upload } from "@aws-sdk/lib-storage";

const getS3Client = async () => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};

  return new S3Client({
    region: cognitoConfig.region,
    requestHandler: new XhrHttpHandler({}),
    credentials: fromCognitoIdentityPool({
      clientConfig: { ...Amplify.getConfig(), region: cognitoConfig.region },
      identityPoolId: cognitoConfig.identityPoolId,
      logins: {
        [getProvider()]: idToken!.toString(),
      },
    }),
  });
};

async function uploadRawVideoFileToExternalPartnerAssets(
  projectId: string,
  date: string,
  file: File,
  onProgress: (progress: any) => void,
  description?: string
) {
  const client = await getS3Client();

  const upload = new Upload({
    client: client,
    params: {
      Metadata: {
        description: description ?? "",
      },
      Key: `public/${projectId}/${date}/${file.name}`,
      Body: file,
      Bucket: s3Config.externalPartnerBucket,
    },
  });
  upload.on("httpUploadProgress", (progress) => {
    onProgress(progress);
  });
  await upload.done();
}

async function getUrlForStoredFile(key: string, bucket?: string) {
  const client = await getS3Client();

  var response = await getSignedUrl(
    client,
    new GetObjectCommand({
      Key: key,
      Bucket: bucket ?? s3Config.bucket,
    })
  );

  return new URL(response);
}

async function uploadFileToExternalPartnerAssets(
  key: string,
  file: File,
  onComplete?: () => void
) {
  const client = await getS3Client();

  client.send(
    new PutObjectCommand({
      Key: key,
      Body: file,
      Bucket: s3Config.externalPartnerBucket,
    }),
    (error, data) => {
      if (onComplete) {
        onComplete();
      }
    }
  );
}

async function uploadFileToFileStorage(
  key: string,
  file: File,
  contentType?: string
) {
  const client = await getS3Client();
  const response = await client.send(
    new PutObjectCommand({
      Key: `public/${key}`,
      Body: file,
      Bucket: s3Config.bucket,
      ContentType: contentType,
    })
  );

  return response;
}

async function uploadVideoFileToFileStorage(key: string, file: File) {
  const client = await getS3Client();
  const response = await client.send(
    new PutObjectCommand({
      Key: key,
      Body: file,
      Bucket: s3Config.bucket,
    })
  );

  return response;
}

const getProvider = () => {
  return `cognito-idp.${cognitoConfig.region}.amazonaws.com/${cognitoConfig.userPoolId}`;
};

export {
  getUrlForStoredFile,
  uploadFileToFileStorage,
  uploadVideoFileToFileStorage,
  uploadFileToExternalPartnerAssets,
  uploadRawVideoFileToExternalPartnerAssets,
};
