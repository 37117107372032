import { lazy } from "react";
import ExperimentDashboard from "../../features/experiment/ExperimentDashboardLayout";
import { RouteObject } from "react-router-dom";

const RolesLibrary = lazy(
  () => import("../../features/experiment/pages/libraries/RoleLibrary")
);

const AccreditationLibrary = lazy(
  () => import("../../features/experiment/pages/libraries/AccreditationLibrary")
);

export const experimentLibraryRoutes = [
  {
    path: "experiment",
    element: <ExperimentDashboard />,
    children: [
      {
        path: "libraries/roles",
        element: <RolesLibrary />,
      },
      {
        path: "libraries/accreditations",
        element: <AccreditationLibrary />,
      },
    ],
  },
] as RouteObject[];
