import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { UploadMixedTasksCsvRequest } from "../../skills/slices/tasksCsvSlice";

async function importMixedTasksCsv(
  request: UploadMixedTasksCsvRequest
): Promise<AxiosPromise> {
  return await axios.post(
    `task-specs/v1/import-mixed-task-spec-from-csv`,
    request
  );
}

export { importMixedTasksCsv };
