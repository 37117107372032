import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";

async function getChallengeDetailsAsync(
  challengeId: string
): Promise<AxiosPromise> {
  return await axios.get(`challenges/v1/${challengeId}`);
}

export { getChallengeDetailsAsync };
