import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import axios from "../../../utils/axios";
import {
  QueryTaskSchedulesRequest,
  TaskSchedule,
  TaskScheduleOptions,
} from "../models/taskSchedule";
import { TaskSpecCadence, ReviewType } from "../models/taskSpecEnums";

type GetTaskSchedulesResponse = {
  data: TaskSchedule[];
  meta: ApiResponseMeta;
};

async function queryTaskSchedules(
  request: QueryTaskSchedulesRequest
): Promise<AxiosPromise<GetTaskSchedulesResponse>> {
  return await axios.post(
    `/task-specs/v1/${request.taskSpecId}/schedules/query`,
    request
  );
}

type CreateTaskSchedulesResponse = {
  data: TaskSchedule;
  meta: ApiResponseMeta;
};

type CreateTaskScheduleRequest = {
  taskSpecId: string;
  name: string;
  challengerTeamId: string;
  reviewerTeamId: string;
  cadenceSpec: TaskSpecCadence;
  active: boolean;
  requiredReviewCount: number;
  reviewWindowInHours: number;
  reviewType: ReviewType;
  options?: TaskScheduleOptions;
  orgId: string;
};

async function createTaskSchedule(
  request: CreateTaskScheduleRequest
): Promise<AxiosPromise<CreateTaskSchedulesResponse>> {
  const body = {
    ...request,
  };

  return await axios.post(
    `/task-specs/v1/${request.taskSpecId}/schedules`,
    body
  );
}

type UpdateTaskScheduleResponse = {
  meta: ApiResponseMeta;
};

type UpdateTaskScheduleRequest = {
  taskSpecId: string;
  taskScheduleId: string;
  name: string;
  challengerTeamId: string;
  reviewerTeamId: string;
  cadenceSpec: TaskSpecCadence;
  active: boolean;
  updateToken?: string;
  requiredReviewCount: number;
  reviewWindowInHours: number;
  reviewType: ReviewType;
  options?: TaskScheduleOptions;
};

async function updateTaskSchedule(
  request: UpdateTaskScheduleRequest
): Promise<AxiosPromise<UpdateTaskScheduleResponse>> {
  const body = {
    ...request,
  };

  return await axios.put(
    `/task-specs/v1/${request.taskSpecId}/schedules/${request.taskScheduleId}`,
    body
  );
}

export {
  queryTaskSchedules as getTaskSchedules,
  updateTaskSchedule,
  createTaskSchedule,
};
