import axios from "../../../utils/axios";
import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import { AccreditationRoleCondition } from "../models/roleCondition";

type GetAccreditationRoleConditionResponse = {
  data: AccreditationRoleCondition;
  meta: ApiResponseMeta;
};

export type CreateAccreditationRoleConditionRequest = {
  orgId: string;
  accreditationRoleId: string;
  orAccreditationIds?: number[];
  andAccreditationIds?: number[];
};

type CreateAccreditationRoleResponse = {
  data: AccreditationRoleCondition;
  meta: ApiResponseMeta;
};

export type UpdateAccreditationRoleConditionRequest = {
  id: string;
  orgId: string;
  accreditationRoleId: string;
  orAccreditationIds?: number[];
  andAccreditationIds?: number[];
};

type UpdateAccreditationRoleConditionResponse = {
  data: AccreditationRoleCondition;
  meta: ApiResponseMeta;
};

export type QueryAccreditationRoleConditionRequest = {
  accreditationRoleId: string;
  orgId: string;
};

type QueryAccreditationRoleConditionResponse = {
  data: AccreditationRoleCondition[];
  meta: ApiResponseMeta;
};

async function createAccreditationRoleConditionAsync(
  body: CreateAccreditationRoleConditionRequest
): Promise<AxiosPromise<CreateAccreditationRoleResponse>> {
  return await axios.post("/accreditations/roles/v1/conditions", body);
}

async function updateAccreditationRoleConditionAsync(
  body: UpdateAccreditationRoleConditionRequest
): Promise<AxiosPromise<UpdateAccreditationRoleConditionResponse>> {
  return await axios.put(
    `/accreditations/roles/v1/conditions/${body.id}`,
    body
  );
}

async function queryAccreditationRoleConditionsAsync(
  body: QueryAccreditationRoleConditionRequest
): Promise<AxiosPromise<QueryAccreditationRoleConditionResponse>> {
  return await axios.post("/accreditations/roles/v1/conditions/query", body);
}

async function getAccreditationRoleConditionAsync(
  id: string
): Promise<AxiosPromise<GetAccreditationRoleConditionResponse>> {
  return await axios.get(`/accreditations/roles/v1/conditions/${id}`);
}

export {
  createAccreditationRoleConditionAsync,
  updateAccreditationRoleConditionAsync,
  getAccreditationRoleConditionAsync,
  queryAccreditationRoleConditionsAsync,
};
