import { AgGridReact } from "ag-grid-react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  ICellRendererParams,
  MenuItemDef,
  ProcessCellForExportParams,
} from "ag-grid-community";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../ag-grid.css";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { useCallback, useRef } from "react";
import { MoreVert } from "@mui/icons-material";
import "ag-grid-enterprise";
import {
  Box,
  Button,
  Card,
  CardContent as MuiCardContent,
  Grid,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  Select,
  TextField,
} from "@mui/material";
import { format } from "date-fns";

const CardContent = styled(MuiCardContent)(spacing);
const InputLabel = styled(MuiInputLabel)(spacing);

type MenuItem = {
  label: string;
  onClick: () => void;
  icon?: string;
  testingClassName: string;
  disabled?: boolean;
  tooltip?: string;
};

type MenuProps<TRowData> = {
  menuItems: (row: TRowData) => MenuItem[];
};

type SearchProps = {
  enabled: boolean;
  disabledColumns?: string[];
};

type SideBarProps = {
  columnSearch?: boolean;
};

type ExportProps = {
  fileTag: string;
};

type AgGridTableProps<TRowData> = {
  data: TRowData[];
  columns: ColDef[];
  menu?: MenuProps<TRowData>;
  gridOptions?: GridOptions<TRowData>;
  pagination?: boolean;
  paginationAutoPageSize?: boolean;
  paginationPageSize?: number;
  dataCyTag: string;
  noRowsOverlay?: () => EmotionJSX.Element;
  search?: SearchProps;
  toolbarElements?: EmotionJSX.Element[];
  disableGrouping?: boolean;
  enableSideBar?: boolean;
  sideBar?: SideBarProps;
  enableExport?: boolean;
  export?: ExportProps;
  enableGrouping?: boolean;
  groupDefaultExpanded?: number;
  masterDetail?: boolean;
  detailCellRenderer?: any;
  autoHeight?: boolean;
};

function AgGridTable<TRowData>(props: AgGridTableProps<TRowData>) {
  const gridRef = useRef<AgGridReact>(null);
  let toolbarElements = props.toolbarElements ?? [];
  const defaultColumnDef = () => {
    return {
      sortable: true,
      filter: true,
      enableRowGroup: props.enableGrouping ?? false,
    } as ColDef;
  };

  if (props.menu) {
    props.columns.push({
      field: "id",
      headerName: "",
      cellClass: "ag-menu-cell",
      width: 75,
      suppressMenu: true,
      getQuickFilterText: () => "",
      cellRenderer: (props: ICellRendererParams<string>) => (
        <MoreVert data-cy={`ellipsisIcon_${props.value}`} />
      ),
      suppressColumnsToolPanel: true,
    });
  }

  if (props.search?.enabled && props.search.disabledColumns) {
    props.search.disabledColumns.forEach((element) => {
      let column = props.columns.find((el) => el.field === element);
      if (column) {
        column.getQuickFilterText = () => "";
      }
    });
  }

  if (props.enableExport) {
    const processCellFunction = (props: ProcessCellForExportParams) => {
      switch (typeof props.value) {
        case "number": {
          var num = props.value as number;
          if (num % 1 !== 0) {
            return num.toFixed(1);
          }
          return num;
        }

        case "string": {
          const dateRegex =
            /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{0,6}\+\d{2}:\d{2}/g;

          var str = props.value as string;
          if (str?.match(dateRegex)) {
            return format(new Date(props.value), "yyyy-MM-dd");
          }
          return props.value;
        }

        default: {
          return props.value;
        }
      }
    };

    toolbarElements.push(
      <Button
        variant="contained"
        onClick={() => {
          gridRef.current?.api.exportDataAsCsv({
            fileName: `${
              props.export?.fileTag ?? "data_export"
            }_${Date.now()}.csv`,
            allColumns: true,
            processCellCallback: processCellFunction,
          });
        }}
      >
        Export CSV
      </Button>
    );
    toolbarElements.push(
      <Button
        variant="contained"
        onClick={() => {
          gridRef.current?.api.exportDataAsExcel({
            fileName: `${
              props.export?.fileTag ?? "data_export"
            }_${Date.now()}.xlsx`,
            allColumns: true,
            processCellCallback: processCellFunction,
          });
        }}
      >
        Export Excel
      </Button>
    );
  }

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): MenuItemDef[] => {
      return (
        props.menu?.menuItems(params.node?.data).map((element) => {
          return {
            name: element.label,
            action: element.onClick,
            icon: element.icon
              ? `<img class="ag-icon" src="/static/img/icons/${element.icon}_icon.png" style="width:18x;height:18px;"/>`
              : null,
            cssClasses: element.testingClassName
              ? ["cy-ag-menu-option-" + element.testingClassName]
              : [],
            disabled: element.disabled,
            tooltip: element.tooltip,
          } as MenuItemDef;
        }) ?? []
      );
    },
    [props.menu]
  );

  const onSearchChanged = (value: string) => {
    if (props.search?.enabled) {
      gridRef.current!.api.setQuickFilter(value);
    }
  };

  if (
    props.pagination &&
    !props.paginationAutoPageSize &&
    props.paginationPageSize === undefined
  ) {
    toolbarElements.push(
      <Card>
        <CardContent p={0} style={{ paddingBottom: 0 }}>
          <Grid container mb={0}>
            <Grid item display="flex" alignItems="center" alignContent="center">
              <InputLabel pr={2} id="pagesize-select-label">
                Page size
              </InputLabel>
            </Grid>
            <Grid item>
              <Select
                labelId="pagesize-select-label"
                name="pageSize"
                defaultValue={25}
                onChange={(value) => {
                  gridRef.current!.api.paginationSetPageSize(
                    Number(value.target.value)
                  );
                }}
                fullWidth
              >
                <MuiMenuItem value={25}>25</MuiMenuItem>
                <MuiMenuItem value={50}>50</MuiMenuItem>
                <MuiMenuItem value={100}>100</MuiMenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const showToolbar = props.search?.enabled || toolbarElements.length > 0;

  return (
    <>
      {showToolbar ? (
        <Box border={"solid #edebe6 1px"} borderBottom={"none"}>
          <Grid container padding={3}>
            <Grid item xs={3}>
              {props.search?.enabled ? (
                <TextField
                  id="search-text-box"
                  type="text"
                  label="Search"
                  onChange={(event) => onSearchChanged(event.target.value)}
                  style={{ maxWidth: 300, height: 52 }}
                  fullWidth
                />
              ) : null}
            </Grid>

            <Grid item xs={9}>
              {toolbarElements.length > 0 ? (
                <Grid container flexDirection="row" justifyContent="flex-end">
                  {toolbarElements.map((element, index) => {
                    return (
                      <Grid
                        item
                        m={2}
                        display="flex"
                        alignItems="center"
                        alignContent="center"
                        key={index}
                      >
                        {element}
                      </Grid>
                    );
                  })}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <div
        className="ag-theme-material"
        style={{
          height: "100%",
          fontSize: 14,
          fontWeight: "normal",
          borderTopLeftRadius: showToolbar ? 0 : 6,
          borderTopRightRadius: showToolbar ? 0 : 6,
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          border: "solid #edebe6 1px",
          overflow: "hidden",
          width: "100%",
        }}
        data-cy={props.dataCyTag}
      >
        <Box height={showToolbar ? "calc(100% - 76px)" : "100%"}>
          <AgGridReact
            ref={gridRef}
            rowData={props.data}
            columnDefs={props.columns}
            paginationAutoPageSize={props.paginationAutoPageSize ?? true}
            pagination={props.pagination ?? true}
            paginationPageSize={props.paginationPageSize}
            defaultColDef={defaultColumnDef()}
            noRowsOverlayComponent={props.noRowsOverlay}
            getContextMenuItems={getContextMenuItems}
            suppressContextMenu={true}
            suppressRowClickSelection={true}
            onCellClicked={(params: any) => {
              if (props.menu) {
                if (params.column.colDef.field === "id") {
                  params.api.contextMenuFactory?.showMenu(
                    params.node,
                    params.column,
                    params.value,
                    params.event
                  );
                }
              }
            }}
            onColumnRowGroupChanged={(e) => {
              e.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
            }}
            isGroupOpenByDefault={() => true}
            gridOptions={props.gridOptions}
            sideBar={
              props.enableSideBar
                ? {
                    toolPanels: [
                      {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                        toolPanelParams: {
                          suppressRowGroups: true,
                          suppressValues: true,
                          suppressPivots: true,
                          suppressPivotMode: true,
                          suppressColumnFilter:
                            !props.sideBar?.columnSearch ?? true,
                        },
                      },
                    ],
                    defaultToolPanel: "columns",
                  }
                : null
            }
            domLayout={props.autoHeight ? "autoHeight" : undefined}
            onGridReady={(props) => {
              props.api.closeToolPanel();
            }}
            tooltipShowDelay={0}
            groupDefaultExpanded={props.groupDefaultExpanded}
            masterDetail={props.masterDetail}
            detailCellRenderer={props.detailCellRenderer}
          ></AgGridReact>
        </Box>
      </div>
    </>
  );
}

export default AgGridTable;
