import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "chart.js/auto";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

// Note: Remove the following line if you want to disable the API mocks.
// import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./ErrorPage";

import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-042468 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Visibly HQ )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Visibly HQ )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Visibly HQ )_need_to_be_licensed___( Visibly HQ )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 May 2024 )____[v2]_MTcxNTgxNDAwMDAwMA==811b6173c53e08133d93ce8a2bd44713"
);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ErrorBoundary fallback={<ErrorPage />}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
