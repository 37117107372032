import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import axios from "../../../utils/axios";
import {
  ChallengeLibraryItem,
  ChallengeTemplate,
} from "../models/challengeLibrary";

type GetChallengeLibraryResponse = {
  data: ChallengeLibraryItem[];
  meta: ApiResponseMeta;
};

async function getChallengeLibrary(
  orgId: string,
  taxonomy?: string
): Promise<AxiosPromise<GetChallengeLibraryResponse>> {
  const body = {
    orgId: orgId,
    taxonomy: taxonomy,
  };

  return await axios.post(`challenge-library/v1/query`, body);
}

type CloneChallengeTemplateResponse = {
  data: ChallengeTemplate;
  meta: ApiResponseMeta;
};

async function cloneChallengeTemplate(
  orgId: string,
  taskSpecId: string
): Promise<AxiosPromise<CloneChallengeTemplateResponse>> {
  const body = {
    orgId: orgId,
    taskSpecId: taskSpecId,
  };

  return await axios.post(`challenge-library/v1/clone-template`, body);
}

export { getChallengeLibrary, cloneChallengeTemplate };
