import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import {
  BulkImportActivityTypeData,
  ActivityTypeDataWithValidation,
} from "../models/activityType";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

type ValidateBulkImportCsvRequest = {
  orgId: string;
  importId: string;
};

type BulkImportRequest = {
  activityTypes: BulkImportActivityTypeData[];
  orgId: string;
};

type ValidateBulkImportCsvResponse = {
  data: ActivityTypeDataWithValidation[];
  meta: ApiResponseMeta;
};

export async function bulkImport(
  request: BulkImportRequest
): Promise<AxiosPromise> {
  const body = {
    activityTypes: request.activityTypes,
    orgId: request.orgId,
  };

  return await axios.post(`checkin/v1/activity-types/bulk-import`, body);
}

export async function validateBulkImportCsv(
  request: ValidateBulkImportCsvRequest
): Promise<AxiosPromise<ValidateBulkImportCsvResponse>> {
  const body = {
    importId: request.importId,
    orgId: request.orgId,
  };

  return await axios.post(
    `checkin/v1/activity-types/validate-bulk-import-csv`,
    body
  );
}
