import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import NavigateToUserHome from "../NavigateToUserHome";

interface GuestGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const { isAuthenticated, isInitialized, currentOrgId } = useAuth();

  if (isInitialized && isAuthenticated) {
    if (currentOrgId) {
      return <NavigateToUserHome />;
    }
    return <Navigate to="/organisation/choose" />;
  }

  return <>{children}</>;
}

export default GuestGuard;
