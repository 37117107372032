import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import AuthGuard from "../components/guards/AuthGuard";
import Loader from "../components/Loader";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

interface LogoFormType {
  children?: React.ReactNode;
}

const LogoForm: React.FC<LogoFormType> = ({ children }) => {
  return (
    <Root>
      <AuthGuard>
        <CssBaseline />
        <GlobalStyle />
        <Suspense fallback={<Loader />}>
          {children}
          <Outlet />
        </Suspense>
      </AuthGuard>
    </Root>
  );
};

export default LogoForm;
