import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { AuditLog } from "../models/auditLog";

type QueryAuditLogsResponse = {
  data: AuditLog[];
  meta: {};
};

async function queryAuditLogsAsync(
  activityId: string
): Promise<AxiosPromise<QueryAuditLogsResponse>> {
  return await axios.get(`/checkin/v1/activity/${activityId}/audit`);
}

export { queryAuditLogsAsync };
