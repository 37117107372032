export const mapAccreditationStatus = (status: string) => {
  switch (status) {
    case "valid":
      return "In date";
    case "expiring":
      return "Due to expire";
    case "expired":
      return "Expired";
    case "notHeld":
      return "Not held";
    default:
      return "";
  }
};
