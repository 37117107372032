import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import axios from "../../../utils/axios";
import { TaskSpec } from "../models/taskSpec";
import {
  GetTaskSpecsRequest,
  UpdateTaskSpecRequest,
} from "../slices/taskSpecSlice";

type GetTaskSpecsResponse = {
  data: TaskSpec[];
  meta: ApiResponseMeta;
};

async function getTaskSpecs(
  request: GetTaskSpecsRequest
): Promise<AxiosPromise<GetTaskSpecsResponse>> {
  return await axios.post(`/task-specs/v1/query`, request);
}

type GetTaskSpecResponse = {
  data: TaskSpec;
  meta: ApiResponseMeta;
};

async function getTaskSpec(
  taskId: string
): Promise<AxiosPromise<GetTaskSpecResponse>> {
  return await axios.get(`/task-specs/v1/${taskId}`);
}

type UpdateTaskSpecResponse = {
  data: TaskSpec;
  meta: ApiResponseMeta;
};

async function updateTaskSpec(
  request: UpdateTaskSpecRequest
): Promise<AxiosPromise<UpdateTaskSpecResponse>> {
  return await axios.put(`/task-specs/v1/${request.id}`, request);
}

export { getTaskSpecs, getTaskSpec, updateTaskSpec };
