import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { Activity, CheckInUser } from "../models/activity";
import { AuditLog } from "../models/auditLog";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

export type CommentActivityRequest = {
  activityId: string;
  comment: string;
  latitude?: number;
  longitude?: number;
  locationAccuracy?: number;
};

export type QueryCheckInUsersRequest = {
  orgId: string;
  states?: string[];
  sessionStates?: string[];
  createdAtTimeRange?: string;
  userId?: string;
  teamIds?: string[];
  activityTypeId?: string;
  alertLevels?: number[];
};

export type CloseActivityRequest = {
  activityId: string;
  comment?: string;
  latitude?: number;
  longitude?: number;
  locationAccuracy?: number;
  resolution: string;
};

type QueryCheckInUsersResponse = {
  data: CheckInUser[];
  meta: ApiResponseMeta;
};

type QueryCheckInResponse = {
  data: Activity[];
  meta: ApiResponseMeta;
};

type GetActivityResponse = {
  data: Activity;
  meta: ApiResponseMeta;
};

type CommentActivityResponse = {
  data: AuditLog;
  meta: ApiResponseMeta;
};

async function queryCheckInUsersAsync(
  request: QueryCheckInUsersRequest
): Promise<AxiosPromise<QueryCheckInUsersResponse>> {
  return await axios.post(`/checkin/v1/activity/user-query`, request);
}

async function getActivityAsync(
  activityId: string
): Promise<AxiosPromise<GetActivityResponse>> {
  return await axios.get(`/checkin/v1/activity/${activityId}`);
}

async function createActivityComment(
  request: CommentActivityRequest
): Promise<AxiosPromise<CommentActivityResponse>> {
  return await axios.post(
    `/checkin/v1/activity/${request.activityId}/comment`,
    request
  );
}

async function closeActivityAsync(
  request: CloseActivityRequest
): Promise<AxiosPromise> {
  return await axios.post(
    `/checkin/v1/activity/${request.activityId}/close`,
    request
  );
}

async function queryUserActivityAsync(
  request: QueryCheckInUsersRequest
): Promise<AxiosPromise<QueryCheckInResponse>> {
  return await axios.post(`/checkin/v1/activity/query`, request);
}

export {
  queryCheckInUsersAsync,
  getActivityAsync,
  closeActivityAsync,
  createActivityComment,
  queryUserActivityAsync,
};
