import { Grid, Typography } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { InnerNavigationItem } from "../../types/sidebar";
import BackButton from "./BackButton";
import InnerNavigationBar from "./InnerNavigationBar";

type PageWrapperProps = {
  children: ReactNode;
  title?: string;
  innerNavigationItems?: InnerNavigationItem[];
  hasBackButton?: boolean;
  backButtonTo?: string;
  button?: ReactElement;
};

function PageWrapper(props: PageWrapperProps) {
  return (
    <>
      <Helmet />
      <Grid container spacing={6}>
        <Grid item xs={9}>
          {props.hasBackButton && props.backButtonTo && (
            <BackButton to={props.backButtonTo} />
          )}
          {props.title && <Typography variant="h2">{props.title}</Typography>}
        </Grid>
        {props.button && (
          <Grid item xs={3}>
            {props.button}
          </Grid>
        )}
        <InnerNavigationBar items={props.innerNavigationItems} />
        {props.children}
      </Grid>
    </>
  );
}

export default PageWrapper;
