import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { Assignment, MultipleAssign } from "../models/assignment";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

export type QueryAssignmentsRequest = {
  orgId: string;
  accreditationRoleId?: string;
  userId?: string;
  isTrainee?: boolean;
};

type QueryAssignmentsResponse = {
  data: Assignment[];
  meta: ApiResponseMeta;
};

export type AssignMultipleRequest = {
  orgId: string;
  assignments: MultipleAssign[];
};

type AssignMultipleResponse = {
  meta: ApiResponseMeta;
};

async function queryAssignmentsAsync(
  body: QueryAssignmentsRequest
): Promise<AxiosPromise<QueryAssignmentsResponse>> {
  return await axios.post(`/accreditations/roles/v1/assignments/query`, body);
}

async function assignMultipleAsync(
  body: AssignMultipleRequest
): Promise<AxiosPromise<AssignMultipleResponse>> {
  return await axios.post(`/accreditations/roles/v1/assign-multiple`, body);
}

export { queryAssignmentsAsync, assignMultipleAsync };
