import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import {
  AccreditationSpec,
  AccreditationSpecAdditionalInfo,
  AccreditationSpecVisibility,
} from "../models/accreditationSpec";
import { QueryAccreditationSpecsRequest } from "../slices/accreditationSpecSlice";

type QueryAccreditationSpecsResponse = {
  data: AccreditationSpec[];
  meta: ApiResponseMeta;
};

async function queryAccreditationSpecsAsync(
  request: QueryAccreditationSpecsRequest
): Promise<AxiosPromise<QueryAccreditationSpecsResponse>> {
  return await axios.post(`/accreditations/specs/v1/query`, request);
}

type CreateAccreditationSpecResponse = {
  meta: ApiResponseMeta;
};

type CreateAccreditationSpecRequest = {
  orgId: string;
  visibility: AccreditationSpecVisibility;
  name: string;
  code: string;
  awardingBodyId?: string;
  additionalInfo?: AccreditationSpecAdditionalInfo;
};

async function createAccreditationSpecAsync(
  request: CreateAccreditationSpecRequest
): Promise<AxiosPromise<CreateAccreditationSpecResponse>> {
  return await axios.post(`/accreditations/specs/v1/`, request);
}

type UpdateAccreditationSpecResponse = {
  meta: ApiResponseMeta;
};

type UpdateAccreditationSpecRequest = {
  orgId: string;
  visibility: AccreditationSpecVisibility;
  name: string;
  code: string;
  awardingBodyId?: string;
  additionalInfo?: AccreditationSpecAdditionalInfo;
};

async function updateAccreditationSpecAsync(
  id: string,
  request: UpdateAccreditationSpecRequest
): Promise<AxiosPromise<UpdateAccreditationSpecResponse>> {
  return await axios.put(`/accreditations/specs/v1/${id}`, request);
}

type GetAccreditationSpecResponse = {
  data: AccreditationSpec;
  meta: ApiResponseMeta;
};

async function getAccreditationSpecAsync(
  id: string
): Promise<AxiosPromise<GetAccreditationSpecResponse>> {
  return await axios.get(`/accreditations/specs/v1/${id}`);
}

export {
  queryAccreditationSpecsAsync,
  getAccreditationSpecAsync,
  createAccreditationSpecAsync,
  updateAccreditationSpecAsync,
};
