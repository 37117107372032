import { Warning } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

function ErrorComponent() {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          top: "calc(50% - 20px)",
          position: "absolute",
          left: "calc(50% - 70px)",
        }}
      >
        <Warning color="error" fontSize="large" style={{ float: "left" }} />
        <Typography variant="body2" color="error" textAlign="center">
          {"An error has occurred."}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ErrorComponent;
