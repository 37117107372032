import { Location } from "react-router-dom";
import queryString from "query-string";

const getTargetOrgIdFromLocation = (location: Location) => {
  const parsedLocation = queryString.parse(location.search);
  const targetOrgId = parsedLocation.targetOrgId;
  if (targetOrgId === undefined) {
    return null;
  }

  return targetOrgId as string;
};

export { getTargetOrgIdFromLocation };
