import { Grid, Typography } from "@mui/material";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import { LoadingStatus } from "../../../../../../models/loadingStatus";
import { AssignedRole, UnassignDto } from "../../../models/assignedRoles";
import Modal from "../../../../../../components/Modal";

type UnassignSingleModalProps = {
  assignedRole: AssignedRole;
  onClose: () => void;
  show: boolean;
  onConfirm: (rolesToUnassign: UnassignDto[]) => Promise<void>;
};

export default function UnassignSingleModal(props: UnassignSingleModalProps) {
  const unassignedRolesStatus = useAppSelector(
    (state) => state.groupRoles.unassignStatus
  );
  return (
    <Modal
      showModal={props.show}
      headerText={"Unassign worker"}
      onClose={props.onClose}
      buttons={[
        {
          text: "Close",
          onClick: props.onClose,
        },
        {
          text: "Unassign",
          isLoadingButton: true,
          onClick: async () => {
            await props.onConfirm([
              {
                roleId: props.assignedRole.id,
                userIds: [props.assignedRole.userId],
              },
            ]);
            props.onClose();
          },
          loading: unassignedRolesStatus === LoadingStatus.loading,
        },
      ]}
    >
      <Grid container padding={5}>
        <Grid item xs={6}>
          <Typography fontWeight={"bold"}>Worker</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{`${props.assignedRole.firstNames} ${props.assignedRole.lastName}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight={"bold"}>Role</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{props.assignedRole.roleName}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
}
